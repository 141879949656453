import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import SEO from './SEO';
import { Tab } from '@headlessui/react';
import { ChevronRightIcon, ChevronDownIcon, ArrowRightIcon, SparklesIcon } from '@heroicons/react/24/solid';
import { useLocation, Link } from 'react-router-dom';

export default function UserGuide() {
  const [selectedModel, setSelectedModel] = useState('v1');
  const [activeSection, setActiveSection] = useState('');
  const location = useLocation();
  const [showScrollButton, setShowScrollButton] = useState(true);
  const [isQuickStartExpanded, setIsQuickStartExpanded] = useState(false);
  const [isWhatToExpectExpanded, setIsWhatToExpectExpanded] = useState(false);
  const [isTroubleshootingExpanded, setIsTroubleshootingExpanded] = useState(false);
  const [isEbookExpanded, setIsEbookExpanded] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const model = params.get('model');
    const section = params.get('section');

    if (model) {
      setSelectedModel(model);
    }
    if (section) {
      setActiveSection(section);
    }

    // Optional: Scroll to the section if specified
    if (section) {
      const sectionElement = document.getElementById(section);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);

  const guides = {
    v1: {
      setup: [
        "1. Unbox your Schumann V1 device",
        "2. Plug in the included AC adapter or charge via USB-C",
        "3. Press the power button - LED indicates operation",
        "4. Place within 6ft of your desired area"
      ],
      operation: [
        "🔵 Blue Light = Magic happening! (Device working & emitting frequency)",
        "🔴 Red Light = Getting energy! (Charging but still working if ON)",
        "💚 Green Light = Full of energy! (Battery fully charged)",
        "⚫ No Light = Sleeping or needs energy (Power off/needs charge)",
        "🤫 Silent Guardian: Works quietly like a ninja",
        "⚡ Power Hero: Works on the move with rechargeable battery",
        "🔌 Always Ready: Built for 24/7 use - perfectly safe to stay plugged in!"
      ],
      placement: [
        "🛏️ Under your bed (like a secret guardian)",
        "🌙 On your nightstand (perfect sleep companion)",
        "🏠 Anywhere in your living space",
        "📏 Keep within 6ft for best results",
        "🐾 Safe for pets and 🌱 plants too!"
      ],
      specialTips: [
        "🔮 Crystal Charging: Place your crystals within 6 inches of device for 24hrs",
        "💧 Water Enhancement: Place your water bottle nearby (8-12 hours recommended)",
      
        "📿 Jewelry Energizing: Enhance your personal items with Earth's frequency",
        "🌱 Plant Growth: Your plants might love the natural frequency too!"
      ]
    
    },
    v2: {
      setup: [
        "1. Unbox your Schumann Pro V2",
        "2. Plug in AC adapter or charge via USB-C",
        "3. Press power button to activate display",
        "4. Use digital controls to select frequency"
      ],
      operation: [
        "• Digital display shows current frequency",
        "• Adjust frequency (0.1-999.99Hz)",
        "• Switch between sine/square waves",
        "• Rechargeable battery life",
        "• Programmable frequency cycles"
      ],
      placement: [
        "🛏️ Under your bed: Ideal for uninterrupted sleep and energy rejuvenation.",
        "🌙 On your nightstand: Enhances sleep quality and relaxation.",
        "🏠 Anywhere in your living space: Maintains a balanced energy field throughout your home.",
        "📏 Within 6ft of use: Ensures optimal frequency reach and effectiveness.",
        "🐾 Safe for pets and 🌱 plants: Promotes well-being for all living things in its vicinity.",
        "🔌 24/7 Operation: Designed to be safe and effective for continuous use, supporting a constant therapeutic environment."
      ],
      specialTips: [
        "🔮 Crystal Charging: Place your crystals within 6 inches of device for 24hrs",
        "💧 Water Enhancement: Place your water bottle nearby (8-12 hours recommended)",
        
        "📿 Jewelry Energizing: Enhance your personal items with Earth's frequency",
        "🌱 Plant Growth: Your plants might love the natural frequency too!"
      ],
    }
  };

  const FrequencyCategory = ({ category, items }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full flex items-center justify-between p-4 rounded-lg bg-purple-900/30 hover:bg-purple-900/40 transition-all"
        >
          <span className="font-medium">{category}</span>
          <motion.div
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <ChevronDownIcon className="h-5 w-5" />
          </motion.div>
        </button>
        
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="relative mt-2 space-y-4"
            >
              {items.map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="relative p-4 rounded-lg bg-purple-900/20 overflow-hidden"
                >
                  {/* Animated sine wave background */}
                  <div className="absolute inset-0 overflow-hidden opacity-10">
                    <motion.div
                      animate={{
                        y: [0, -10, 0],
                      }}
                      transition={{
                        duration: item.waveSpeed || "10s",
                        repeat: Infinity,
                        ease: "easeInOut",
                      }}
                      className={`absolute inset-0 bg-gradient-to-r ${
                        item.color || 'from-purple-500 to-pink-500'
                      }`}
                    />
                  </div>

                  {/* Content */}
                  <div className="relative z-10">
                    <div className="flex justify-between items-center mb-2">
                      <h3 className="text-lg font-semibold text-purple-400">
                        {item.title}
                      </h3>
                      <span className="text-sm font-mono bg-purple-900/40 px-2 py-1 rounded">
                        {item.frequency}
                      </span>
                    </div>
                    <p className="text-gray-300 mb-2">{item.description}</p>
                    <ul className="space-y-1">
                      {item.benefits.map((benefit, idx) => (
                        <li key={idx} className="text-gray-400 text-sm">
                          {benefit}
                        </li>
                      ))}
                    </ul>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  const CollapsibleSection = ({ title, isExpanded, setIsExpanded, gradient, iconColor, children }) => (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`bg-gradient-to-r ${gradient} rounded-xl overflow-hidden border border-${iconColor}-500/20 mb-8`}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          setIsExpanded(!isExpanded);
        }}
        className="w-full p-6 flex justify-between items-center hover:bg-black/20 transition-colors"
        type="button"
      >
        <h2 className={`text-2xl font-bold text-center bg-gradient-to-r from-${iconColor}-400 to-${iconColor === 'purple' ? 'pink' : iconColor === 'yellow' ? 'orange' : 'purple'}-400 bg-clip-text text-transparent`}>
          {title}
        </h2>
        <ChevronDownIcon 
          className={`h-6 w-6 text-${iconColor}-400 transition-transform duration-300 transform ${
            isExpanded ? 'rotate-180' : ''
          }`}
        />
      </button>

      <AnimatePresence initial={false}>
        {isExpanded && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="overflow-hidden"
          >
            <div className="p-6 pt-0">
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );

  const QuickStartGuide = () => (
    <CollapsibleSection
      title="🚀 3-Step Quick Start Guide"
      isExpanded={isQuickStartExpanded}
      setIsExpanded={setIsQuickStartExpanded}
      gradient="from-purple-900/30 to-pink-900/30"
      iconColor="purple"
    >
      <div className="grid md:grid-cols-3 gap-6">
        <div className="bg-black/30 p-4 rounded-lg border border-purple-500/20">
          <div className="text-3xl mb-2">1️⃣</div>
          <h3 className="text-lg font-semibold text-purple-400 mb-2">Plug & Power</h3>
          <p className="text-gray-300 text-sm">
            Just plug it in and press the power button.Any light means it's working! That's it!
          </p>
        </div>
        
        <div className="bg-black/30 p-4 rounded-lg border border-purple-500/20">
          <div className="text-3xl mb-2">2️⃣</div>
          <h3 className="text-lg font-semibold text-purple-400 mb-2">Place It</h3>
          <p className="text-gray-300 text-sm">
            Put it near your bed or desk (within 6 feet). No special setup needed!
          </p>
        </div>
        
        <div className="bg-black/30 p-4 rounded-lg border border-purple-500/20">
          <div className="text-3xl mb-2">3️⃣</div>
          <h3 className="text-lg font-semibold text-purple-400 mb-2">Enjoy!</h3>
          <p className="text-gray-300 text-sm">
            That's all! The device works automatically. Just live your life and feel better!
          </p>
        </div>
      </div>

      <div className="mt-6 bg-green-900/20 p-4 rounded-lg border border-green-500/20">
        <h3 className="text-lg font-semibold text-green-400 mb-2 flex items-center">
          <span className="text-xl mr-2">✨</span> Pro Tip
        </h3>
        <p className="text-gray-300 text-sm">
          Most users feel results within 3-7 days. Some feel it instantly! Everyone's different, and that's okay.
        </p>
      </div>
    </CollapsibleSection>
  );

  const TroubleshootingGuide = () => (
    <CollapsibleSection
      title="🔧 Simple Troubleshooting"
      isExpanded={isTroubleshootingExpanded}
      setIsExpanded={setIsTroubleshootingExpanded}
      gradient="from-blue-900/30 to-purple-900/30"
      iconColor="blue"
    >
      <div className="space-y-4">
        <div className="bg-black/30 p-4 rounded-lg border border-blue-500/20">
          <h3 className="text-lg font-semibold text-blue-400 mb-2">No Light? 💡</h3>
          <ul className="text-gray-300 text-sm space-y-2">
            <li>• Check if it's plugged in</li>
            <li>• Press the power button once</li>
            <li>• Try a different outlet</li>
          </ul>
        </div>
        
        <div className="bg-black/30 p-4 rounded-lg border border-blue-500/20">
          <h3 className="text-lg font-semibold text-blue-400 mb-2">Red Light? 🔴</h3>
          <p className="text-gray-300 text-sm">
            No worries! Just means it's charging. It still works perfectly!
          </p>
        </div>
        
        <div className="bg-black/30 p-4 rounded-lg border border-blue-500/20">
          <h3 className="text-lg font-semibold text-blue-400 mb-2">Not Sure If It's Working? 🤔</h3>
          <p className="text-gray-300 text-sm">
            If you see any light, it's working! The device is silent by design. Trust the process!
          </p>
        </div>
      </div>
    </CollapsibleSection>
  );

  const WhatToExpect = () => (
    <CollapsibleSection
      title="🌟 What You Might Feel"
      isExpanded={isWhatToExpectExpanded}
      setIsExpanded={setIsWhatToExpectExpanded}
      gradient="from-yellow-900/30 to-orange-900/30"
      iconColor="yellow"
    >
      <div className="p-6 pt-0">
        <div className="grid md:grid-cols-2 gap-6">
          <div className="bg-black/30 p-4 rounded-lg border border-yellow-500/20">
            <h3 className="text-lg font-semibold text-yellow-400 mb-2">First Few Days</h3>
            <ul className="text-gray-300 text-sm space-y-2">
              <li>• Better sleep quality</li>
              <li>• Deeper relaxation</li>
              <li>• Clearer mind</li>
              <li>• Subtle tingling sensations</li>
              <li>• Gentle buzzing feeling near device</li>
              <li>• Some might feel nothing (perfectly normal!)</li>
              <li>• Increased dream recall</li>
              <li>• Feeling of lightness</li>
            </ul>
          </div>
          
          <div className="bg-black/30 p-4 rounded-lg border border-yellow-500/20">
            <h3 className="text-lg font-semibold text-yellow-400 mb-2">After 1-2 Weeks</h3>
            <ul className="text-gray-300 text-sm space-y-2">
              <li>• Increased energy levels</li>
              <li>• Enhanced mood</li>
              <li>• Sharper focus</li>

              <li>• Feel more "in tune" with nature</li>
              <li>• Heightened intuition</li>
              <li>• Better emotional balance</li>
              <li>• Increased vitality</li>
            </ul>
          </div>
        </div>

        <div className="mt-6 bg-purple-900/20 p-4 rounded-lg border border-purple-500/20">
          <h3 className="text-lg font-semibold text-purple-400 mb-2">Important Note 🌈</h3>
          <p className="text-gray-300 text-sm">
            Everyone's experience is unique! The tingling or buzzing sensations are signs that your body is responding to the frequencies. 
            These sensations often occur in areas that need energetic balance. Trust your body's wisdom - it knows exactly what it needs.
          </p>
        </div>

        {/* Deepening Your Connection Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mt-12 space-y-6"
        >
          <h2 className="text-2xl font-bold bg-gradient-to-r from-yellow-400 to-amber-600 bg-clip-text text-transparent">
            Deepening Your Connection
          </h2>

          <div className="grid md:grid-cols-2 gap-6">
            <div className="bg-black/30 p-6 rounded-lg border border-yellow-500/20">
              <h3 className="text-lg font-semibold text-yellow-400 mb-4">Getting Started</h3>
              <ul className="space-y-3 text-gray-300 text-sm">
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  <span>
                    <span className="font-medium text-yellow-300">Start Slow:</span> Begin with short sessions during relaxation or meditation
                  </span>
                </li>
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  <span>
                    <span className="font-medium text-yellow-300">Explore Times:</span> Try morning for energy, midday for focus, evening for relaxation
                  </span>
                </li>
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  <span>
                    <span className="font-medium text-yellow-300">Track Progress:</span> Note which frequencies and times work best for you
                  </span>
                </li>
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  <span>
                    <span className="font-medium text-yellow-300">Be Patient:</span> Allow your body time to attune to the frequencies
                  </span>
                </li>
              </ul>
            </div>

            <div className="bg-black/30 p-6 rounded-lg border border-yellow-500/20">
              <h3 className="text-lg font-semibold text-yellow-400 mb-4">What You Might Experience</h3>
              <ul className="space-y-3 text-gray-300 text-sm">
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  <span>
                    <span className="font-medium text-yellow-300">Tingling Sensations:</span> A sign of cellular interaction and healing
                  </span>
                </li>
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  <span>
                    <span className="font-medium text-yellow-300">Subtle Vibrations:</span> Frequency resonance with your natural rhythms
                  </span>
                </li>
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  <span>
                    <span className="font-medium text-yellow-300">Energy Shifts:</span> Changes in awareness and vitality levels
                  </span>
                </li>
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  <span>
                    <span className="font-medium text-yellow-300">Healing Response:</span> Temporary intensification in healing areas
                  </span>
                </li>
              </ul>
            </div>
          </div>

          

          <div className="bg-gradient-to-r from-black/30 to-yellow-900/20 p-6 rounded-lg border border-yellow-500/20">
            <p className="text-center text-gray-300 text-sm italic">
              "Remember, frequency therapy is a personal journey. Each person's experience is unique, and the key is finding what resonates best with your body and lifestyle. Trust the process and let the natural frequencies guide your path to wellness."
            </p>
          </div>
        </motion.div>

  
      </div>
    </CollapsibleSection>
  );

  const EbookSection = () => (
    <CollapsibleSection
      title="📚 The Complete Schumann Guide"
      isExpanded={isEbookExpanded}
      setIsExpanded={setIsEbookExpanded}
      gradient="from-purple-900/30 to-pink-900/30"
      iconColor="purple"
    >
      <div className="space-y-6">
        {/* Ebook Preview with Glow Effect */}
        <div className="flex flex-col md:flex-row gap-8">
          <div className="md:w-1/2">
            <div className="relative group">
              {/* Glow Effect */}
              <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-25 group-hover:opacity-40 transition duration-1000 group-hover:duration-200"></div>
              
              <div className="relative bg-black rounded-lg overflow-hidden">
                <img 
                  src="/Screenshot 2025-01-24 at 14-05-34 Minimal Modern Online Business Ebook - The Schumann Guide-3.pdf.png"
                  alt="The Schumann Guide Preview"
                  className="w-full h-auto rounded-lg transform transition-transform duration-500 group-hover:scale-105"
                />
                {/* Overlay with shine effect */}
                <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-pink-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
              </div>
            </div>
          </div>
          
          <div className="md:w-1/2 space-y-6">
            <div>
              <h3 className="text-2xl font-bold bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent mb-2">
                50+ Pages of Expert Knowledge
              </h3>
              <p className="text-gray-300">
                Your comprehensive guide to mastering frequency therapy and the Schumann resonance
              </p>
            </div>
            
            <div className="space-y-4">
              <h4 className="text-lg font-semibold text-purple-400">What's Inside:</h4>
              <ul className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                {[
                  "Complete Frequency Guide",
                  "Scientific Research & Studies",
                  "Advanced Usage Techniques",
                  "Optimization Strategies",
                  "Frequency Combinations",
                  "Expert Tips & Tricks",
                  "Troubleshooting Guide",
                  "Best Practices & Safety"
                ].map((item, index) => (
                  <li key={index} className="flex items-center gap-2 text-gray-300">
                    <span className="text-purple-400">✦</span>
                    {item}
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex flex-col sm:flex-row gap-4">
              <a 
                href="/The Schumann Guide-1.pdf"
                download="The Schumann Guide.pdf"
                className="inline-flex items-center justify-center gap-2 bg-gradient-to-r from-purple-600 to-pink-600 
                         text-white px-6 py-3 rounded-lg font-semibold 
                         hover:from-purple-700 hover:to-pink-700 
                         transition-all duration-200 transform hover:scale-105"
              >
                <span>Download Free Guide</span>
                <ArrowRightIcon className="h-5 w-5" />
              </a>
              <button
                onClick={() => window.open('/The Schumann Guide-1.pdf', '_blank')}
                className="inline-flex items-center justify-center gap-2 bg-black/40 
                         text-white px-6 py-3 rounded-lg font-semibold border border-purple-500/20
                         hover:bg-black/60 transition-all duration-200"
              >
                <span>Preview Online</span>
              </button>
            </div>
          </div>
        </div>

        {/* Additional Features */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-8">
          <div className="bg-black/30 p-4 rounded-lg border border-purple-500/20">
            <div className="text-xl mb-2">📱</div>
            <h4 className="font-semibold text-purple-400 mb-1">Mobile Friendly</h4>
            <p className="text-sm text-gray-400">Read anywhere on any device</p>
          </div>
          
          <div className="bg-black/30 p-4 rounded-lg border border-purple-500/20">
            <div className="text-xl mb-2">🔄</div>
            <h4 className="font-semibold text-purple-400 mb-1">Free Updates</h4>
            <p className="text-sm text-gray-400">Access latest research & tips</p>
          </div>
          
          <div className="bg-black/30 p-4 rounded-lg border border-purple-500/20">
            <div className="text-xl mb-2">💫</div>
            <h4 className="font-semibold text-purple-400 mb-1">Exclusive Content</h4>
            <p className="text-sm text-gray-400">Advanced techniques & insights</p>
          </div>
        </div>
      </div>
    </CollapsibleSection>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-zinc-900 text-white pt-24 sm:pt-32">
      <SEO 
        title="User Guide | The Schumann Device Manual"
        description="Complete user guide for Schumann V1 and Pro V2 devices. Setup instructions, operation guidelines, and frequency recommendations."
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h1 
          className="text-3xl sm:text-5xl md:text-7xl font-bold mx-auto max-w-[95%] sm:max-w-none text-center mb-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-purple-200 to-white inline-block">
            User Guide &
          </span>
          <br />
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600 inline-block">
            Frequency Optimization
          </span>
        </motion.h1>
        
        <div className="text-center max-w-3xl mx-auto mb-12 space-y-4">
          <p className="text-lg text-gray-300">
           Schumann Wave generators™  are powerful devices that can significantly alter your bioenergetic state. 
            Read our comprehensive guide to start readjusting and optimizing your body with the frequencies it naturally craves.
          </p>
          <p className="text-sm text-purple-400/80">
            Whether you're new to frequency therapy or an experienced practitioner, this guide will help you maximize your device's potential.
          </p>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mt-8 bg-gradient-to-r from-purple-900/30 to-pink-900/30 rounded-xl p-6 border border-purple-500/20"
          >
            <div className="text-center">
              <h2 className="text-2xl font-bold mb-4 bg-gradient-to-r from-purple-400 to-pink-600 bg-clip-text text-transparent">
                Explore All Frequencies
              </h2>
              <p className="text-gray-300 mb-6">
                Discover our complete guide to all available frequencies, their benefits, and how to use them effectively.
              </p>
              <Link
                to="/frequencies"
                className="inline-flex items-center gap-2 bg-gradient-to-r from-purple-600 to-pink-600 text-white px-8 py-4 rounded-lg font-semibold hover:from-purple-700 hover:to-pink-700 transition-all duration-200 transform hover:scale-105"
              >
                <SparklesIcon className="h-5 w-5" />
                <span>View Full Frequency Guide</span>
              </Link>
            </div>
          </motion.div>
        </div>

        <EbookSection />

        <QuickStartGuide />
        <WhatToExpect />
        <TroubleshootingGuide />

        <Tab.Group>
          <Tab.List className="flex space-x-2 mb-6 rounded-xl bg-purple-900/20 p-1">
            <Tab
              className={({ selected }) =>
                `flex-1 py-2.5 text-sm font-medium rounded-lg transition-all ${
                  selected 
                    ? 'bg-gradient-to-r from-[#FFD700] to-[#B8860B] text-black shadow-lg' 
                    : 'text-purple-400 hover:bg-purple-900/40 hover:text-white'
                }`
              }
              onClick={() => setSelectedModel('v2')}
            >
              Pro V2
            </Tab>
            <Tab
              className={({ selected }) =>
                `flex-1 py-2.5 text-sm font-medium rounded-lg transition-all ${
                  selected 
                    ? 'bg-purple-600 text-white shadow-lg' 
                    : 'text-purple-400 hover:bg-purple-900/40 hover:text-white'
                }`
              }
              onClick={() => setSelectedModel('v1')}
            >
              V1 Classic
            </Tab>
          </Tab.List>

          <Tab.Panels>
            {['v2', 'v1'].map((model) => (
              <Tab.Panel key={model}>
                <div className="space-y-4">
                  {/* Other Sections for V2 and V1 */}
                  {Object.entries(guides[model]).map(([section, items]) => {
                    if (section !== 'frequencies') {
                      return (
                        <motion.div
                          key={section}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          className="bg-purple-900/20 rounded-xl p-4 sm:p-6 border border-purple-500/20 backdrop-blur-sm"
                        >
                          <h2 className="text-lg sm:text-xl font-semibold mb-3 capitalize bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
                            {section.replace(/([A-Z])/g, ' $1').trim()}
                          </h2>
                          <ul className="space-y-2">
                            {items.map((item, index) => (
                              <li key={index} className="flex items-start gap-2 text-gray-300 text-sm sm:text-base">
                                <ChevronRightIcon className="h-5 w-5 text-purple-400 flex-shrink-0 mt-0.5" />
                                <span>{item}</span>
                              </li>
                            ))}
                          </ul>
                        </motion.div>
                      );
                    }
                    return null;
                  })}
                </div>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
     
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mt-8 text-center bg-gradient-to-r from-purple-900/30 to-pink-900/30 p-6 rounded-xl border border-purple-500/20"
        >
          <h3 className="text-xl font-bold text-purple-400 mb-4">
            Ready to Experience Earth's Natural Frequency?
          </h3>
          <p className="text-gray-300 mb-6">
            Join thousands who've already discovered the power of natural frequencies
          </p>
         
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button
              onClick={() => window.location.href = '/product'}
              className="bg-gradient-to-r from-purple-600 to-pink-600 text-white 
                px-6 py-3 rounded-lg font-semibold 
                hover:from-purple-700 hover:to-pink-700 
                transition-all duration-200 
                transform hover:scale-105
                flex items-center justify-center gap-2"
            >
              <span>Get V1 Classic</span>
              <span className="text-sm">$69.99</span>
            </button>
            
            <button
              onClick={() => window.location.href = '/product'}
              className="bg-gradient-to-r from-[#FFD700] to-[#B8860B] text-black 
                px-6 py-3 rounded-lg font-semibold 
                hover:from-[#B8860B] hover:to-[#FFD700]
                transition-all duration-200 
                transform hover:scale-105
                flex items-center justify-center gap-2"
            >
              <span>Upgrade to Pro V2</span>
              <span className="text-sm">$169.00</span>
            </button>
          </div>
          <p className="mt-4 text-sm text-gray-400">
            ✨ Free Express Shipping • 30-Day Money Back Guarantee
          </p>
        </motion.div>
      </div>
      <motion.div
        initial={{ y: 100 }}
        animate={{ y: showScrollButton ? 0 : 100 }}
        transition={{ 
          duration: 0.3,
          type: "spring",
          stiffness: 260,
          damping: 20
        }}
        className="fixed bottom-0 left-0 right-0 bg-black/95 backdrop-blur-sm px-4 py-2 sm:p-4 z-50 border-t border-gray-800 shadow-lg"
        style={{ 
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          transform: `translateY(${showScrollButton ? '0' : '100%'})`,
          willChange: 'transform'
        }}
      >
        <div className="max-w-7xl mx-auto flex justify-between items-center gap-1 sm:gap-4">
          <div className="text-sm sm:text-lg font-bold text-white whitespace-nowrap">
            Transform Your Well-being Today
          </div>
          <Link
            to="/product#products"
            className="bg-white font-semibold rounded-lg 
              hover:bg-gray-100 transition-all duration-300 transform hover:scale-105
              border border-gray-200 px-4 sm:px-6 py-2 sm:py-3 flex items-center gap-0.5 sm:gap-2 whitespace-nowrap"
          >
            <span className="bg-gradient-to-r from-[#FFD700] to-[#B8860B] bg-clip-text text-transparent text-sm sm:text-base">
              Shop Now
            </span>
            <ArrowRightIcon className="h-4 w-4 sm:h-5 sm:w-5 text-[#B8860B]" />
          </Link>
        </div>
      </motion.div>
    </div>
  );
} 