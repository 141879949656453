import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const Section = ({ title, children, isOpen, onClick }) => {
  return (
    <div className="border-b border-gray-200 last:border-b-0">
      <button
        onClick={onClick}
        className="flex justify-between items-center w-full py-4 px-6 text-left hover:bg-gray-50 transition-colors"
      >
        <h2 className="text-lg font-semibold">{title}</h2>
        {isOpen ? (
          <ChevronUp className="h-5 w-5 text-gray-500" />
        ) : (
          <ChevronDown className="h-5 w-5 text-gray-500" />
        )}
      </button>
      {isOpen && (
        <div className="px-6 pb-4 prose prose-sm max-w-none">
          {children}
        </div>
      )}
    </div>
  );
};

const TermsOfService = () => {
  const [openSections, setOpenSections] = useState(new Set());

  const toggleSection = (index) => {
    const newOpenSections = new Set(openSections);
    if (newOpenSections.has(index)) {
      newOpenSections.delete(index);
    } else {
      newOpenSections.add(index);
    }
    setOpenSections(newOpenSections);
  };

  const sections = [
    {
      title: "1. Introduction and Agreement to Terms",
      content: (
        <>
          <p>By accessing, browsing, or making a purchase from The Schuman Technologies ("we," "our," "us," "the Company"), you acknowledge and agree to be bound by the terms and conditions outlined in these Terms of Service.</p>
        </>
      )
    },
    {
      title: "2. Services and Operations",
      content: (
        <>
          <h3 className="text-lg font-semibold mb-2">2.1 Outsourcing and Third-Party Suppliers</h3>
          <p>The Company operates a outsourcing business model, meaning that products are sourced from third-party manufacturers and shipped directly to you. By using our service, you acknowledge and accept the potential for:</p>
          <ul className="list-disc pl-5 mb-4">
            <li>Products shipped from external manufacturers</li>
            <li>Variability in shipping times and product packaging</li>
            <li>Changes in manufacturing locations or suppliers</li>
          </ul>
          <h3 className="text-lg font-semibold mb-2">2.2 No Guarantee of Availability</h3>
          <p>We do not guarantee that any products will be available at all times, nor do we guarantee shipping times or product quality beyond the manufacturer's specifications.</p>
        </>
      )
    },
    {
      title: "3. Liability Limitations and Disclaimers",
      content: (
        <>
          <h3 className="text-lg font-semibold mb-2">3.1 General Disclaimers</h3>
          <p>The Company disclaims liability for:</p>
          <ul className="list-disc pl-5 mb-4">
            <li>Any product defects or issues caused by third-party manufacturers</li>
            <li>Delays in shipping, damages during transit, or errors in delivery</li>
            <li>Misrepresentation of product features or specifications</li>
            <li>Changes in pricing, availability, or product listings</li>
            <li>Any claims arising from product use or misuse</li>
            <li>Any indirect, incidental, or consequential damages resulting from your use of the service</li>
          </ul>
        </>
      )
    },
    {
      title: "4. Payment and Refund Policies",
      content: (
        <>
          <h3 className="text-lg font-semibold mb-2">4.1 Pricing and Payments</h3>
          <p>All prices listed are final, subject to change, and exclusive of taxes, shipping, or additional fees. Payments are processed immediately upon checkout, and you agree to provide accurate billing information.</p>
          
          <h3 className="text-lg font-semibold mb-2">4.2 Return and Refund Policy</h3>
          <p>We offer a 30-day money-back guarantee, subject to the following terms:</p>
          <ul className="list-disc pl-5 mb-4">
            <li>Refund requests must be made within 30 days of purchase</li>
            <li>Products must be returned in original condition</li>
            <li>Return shipping costs are the responsibility of the customer</li>
            <li>Refunds exclude original shipping costs and transaction fees</li>
            <li>Refund will be processed within 5-7 business days of receiving the returned item</li>
            <li>Any attempt at fraud will result in immediate cancellation of the refund</li>
          </ul>
        </>
      )
    },
    {
      title: "5. Privacy and Data Protection",
      content: (
        <>
          <h3 className="text-lg font-semibold mb-2">5.1 Privacy Commitment</h3>
          <p>The Company is committed to protecting your privacy and complies with applicable data protection regulations, including GDPR. We collect and store personal information for processing orders, and we will never sell or share your data with third parties without consent.</p>

          <h3 className="text-lg font-semibold mb-2">5.2 Data Retention</h3>
          <p>We retain personal data only for as long as necessary to fulfill the purposes for which it was collected, after which it will be deleted or anonymized, in accordance with our Privacy Policy.</p>
        </>
      )
    },
    {
      title: "6. Termination and Account Suspension",
      content: (
        <>
          <h3 className="text-lg font-semibold mb-2">6.1 Termination of Access</h3>
          <p>We reserve the right to suspend or terminate your access to our services at any time and without notice, should we suspect you have violated these Terms of Service or engaged in fraudulent activities.</p>
        </>
      )
    },
    {
        title: "7. LLM-Generated Content Disclaimer",
        content: (
          <>
            <p>All content on this website, including but not limited to testimonials, names, and other personal details, is entirely fictional. Any resemblance to actual persons, living or dead, is purely coincidental.</p>
            <p>Additionally, we make no guarantees or confirmations about the accuracy, reliability, or effectiveness of any products, services, or information provided on this website. All content is created for illustrative purposes and is subject to change.</p>
          </>
        )
      },
    {
      title: "8. International and Cross-Border Transactions",
      content: (
        <>
          <h3 className="text-lg font-semibold mb-2">7.1 Cross-Border Regulations</h3>
          <p>We operate internationally, with transactions processed in USD and comply with relevant international trade and tax regulations. Any customs or import duties are the responsibility of the customer.</p>

          <h3 className="text-lg font-semibold mb-2">7.2 Jurisdiction</h3>
          <p>These terms are governed by the laws of the jurisdiction in which the Company is established, and any disputes will be resolved in that jurisdiction.</p>
        </>
      )
    },
    {
      title: "9. Contact Information",
      content: (
        <>
          <p>If you have any questions or concerns about these Terms of Service, please contact us:</p>
          <p>The Schuman Technologies</p>
          <p>Email: support@theschumann.com</p>
          <p>Hours: 24/7</p>
        </>
      )
    }
  ];

  return (
    <div className="w-full max-w-4xl mx-auto mt-20">
      <div className="bg-gray-50 border-b border-gray-200 py-6 px-6">
        <h1 className="text-2xl text-gray-500  font-semibold">Terms of Service</h1>
        <p className="text-sm text-gray-500">Last Updated: January, 2025</p>
      </div>
      <div className="p-0">
        {sections.map((section, index) => (
          <Section
            key={index}
            title={section.title}
            isOpen={openSections.has(index)}
            onClick={() => toggleSection(index)}
          >
            {section.content}
          </Section>
        ))}
      </div>
    </div>
  );
};

export default TermsOfService;
