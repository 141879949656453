import { useState, useEffect } from 'react';
import { useCart } from '../context/CartContext';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircleIcon, ShieldCheckIcon, TruckIcon, SparklesIcon, StarIcon, BookOpenIcon, CreditCardIcon, LockClosedIcon, MoonIcon, ChevronDownIcon, HeartIcon, XMarkIcon, GiftIcon, UserIcon, HomeIcon, AcademicCapIcon, UserGroupIcon, BuildingOfficeIcon, ChevronLeftIcon, ChevronRightIcon, BeakerIcon, ChevronUpIcon, ArrowRightIcon, ArrowUpIcon, ChartBarIcon, DevicePhoneMobileIcon, ComputerDesktopIcon, WifiIcon, SignalIcon, ExclamationTriangleIcon, InformationCircleIcon, BoltIcon, FireIcon, ShoppingCartIcon } from '@heroicons/react/24/solid';
import { PhotoIcon } from '@heroicons/react/24/outline'; // Add this import
import SEO from './SEO';
import { seoConfig } from '../config/seo-config';
import nytLogo from '../assets/logos-png/nyt-logo.png';
import forbesLogo from '../assets/logos-png/forbes-logo.png';
import nasaLogo from '../assets/logos-png/nasa-logo.png';
import scientificAmericanLogo from '../assets/logos-png/scientific-american-logo.png';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FaPaypal, FaCcStripe, FaLock, FaShieldAlt, FaCreditCard } from 'react-icons/fa';
import { WiStars } from 'react-icons/wi';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Earth3D from './Earth3D';



const productImages = [
  {
    src: "/slike/1.webp",
    alt: "Schumann Device Front View",
    height: 800,
    loading: "lazy",
    description: "Professional grade Schumann resonance device"
  },
  {
    src: "/slike/2.webp",
    alt: "Schumann Device Perspective",
    height: 800,
    loading: "lazy",
    description: "Premium build quality and design"
  },
  {
    src: "/slike/3.webp",
    alt: "Schumann Device Side View",
    height: 800,
    loading: "lazy",
    description: "Sleek side profile with USB-C charging"
  },
  {
    src: "/slike/4.webp",
    alt: "Schumann Device Detail",
    height: 800,
    loading: "lazy",
    description: "Precision engineered components"
  },
  {
    src: "/slike/5.webp",
    alt: "Schumann Device Features",
    height: 800,
    loading: "lazy",
    description: "Advanced frequency generation capabilities"
  },
  {
    src: "/slike/6.webp",
    alt: "Schumann Device Complete View",
    height: 800,
    loading: "lazy",
    description: "Full device showcase with all features"
  }
];
const reviews = [
  { src: "/REVSLIKE/REV2_11zon.webp", alt: "Review 2" },
  { src: "/REVSLIKE/REV3_11zon.webp", alt: "Review 3" },
  { src: "/REVSLIKE/REV4_11zon.webp", alt: "Review 4" },
  { src: "/REVSLIKE/REV5_11zon.webp", alt: "Review 5" },
  { src: "/REVSLIKE/REV6_11zon.webp", alt: "Review 6" },
  { src: "/REVSLIKE/REV7_11zon.webp", alt: "Review 7" },
  { src: "/REVSLIKE/REV8_11zon.webp", alt: "Review 8" },
  { src: "/REVSLIKE/REV9_11zon.webp", alt: "Review 9" },
  { src: "/REVSLIKE/REV10_11zon.webp", alt: "Review 10" },
  { src: "/REVSLIKE/REV11_11zon.webp", alt: "Review 11" },
  { src: "/REVSLIKE/REV12_11zon.webp", alt: "Review 12" },
  { src: "/REVSLIKE/REV13_11zon.webp", alt: "Review 13" },
  { src: "/REVSLIKE/REV14_11zon.webp", alt: "Review 14" },
  { src: "/REVSLIKE/REV15_11zon-1.webp", alt: "Review 15" },
  { src: "/REVSLIKE/REV16_11zon.webp", alt: "Review 16" }
];

const benefits = [
  {
    title: "Sleep Mastery",
    description: "94% report deeper sleep from day one",
    icon: MoonIcon,
    color: "from-blue-500 to-indigo-600",
    details: {
      title: "Transform Your Sleep Forever",
      description: "Experience optimal sleep frequencies for each sleep phase. Pro users can customize frequencies for personal sleep cycles, while V1 delivers the proven 7.83Hz for deep rest.",
      stats: ["94% better sleep quality", "47% faster sleep onset", "82% longer deep sleep"],
      science: "Multiple frequency ranges proven to enhance different sleep phases"
    }
  },
  {
    title: "Vagus Nerve Reset",
    description: "Activate your body's healing switch",
    icon: BoltIcon,
    color: "from-emerald-500 to-teal-600",
    details: {
      title: "Your Body's Master Health Switch",
      description: "Target specific healing frequencies for vagal tone optimization. Pro version allows precise frequency selection for personalized vagal stimulation and enhanced recovery.",
      stats: ["89% reduced inflammation", "73% better stress response", "91% improved recovery"],
      science: "Customizable frequencies for optimal vagal response and healing"
    }
  },
  {
    title: "EMF Shield",
    description: "24/7 protection from digital toxins",
    icon: ShieldCheckIcon,
    color: "from-purple-500 to-pink-600",
    details: {
      title: "Your Invisible Shield",
      description: "V1 creates a powerful 7.83Hz protective field, while Pro version offers enhanced multi-frequency shielding with adjustable harmonization for any environment.",
      stats: ["98% EMF neutralization", "190-250ft² protection radius", "Multi-frequency shield"],
      science: "V1: 7.83Hz protective field | Pro: Advanced frequency EMF Protection (0.1-999.99Hz)"
    }
  },
  {
    title: "Focus Flow",
    description: "43% sharper mental clarity",
    icon: SparklesIcon,
    color: "from-amber-500 to-orange-600",
    details: {
      title: "Unlock Peak Mental Performance",
      description: "V1 harnesses 7.83Hz for enhanced focus and mental clarity, while Pro users can fine-tune specific frequencies for different cognitive states - from deep work to meditation.",
      stats: ["43% better focus", "67% reduced brain fog", "58% improved memory"],
      science: "V1: Classic 7.83Hz focus | Pro: Multi-state cognitive enhancement (0.1-999.99Hz)"
    
    }
  },
  {
    title: "Energy Field",
    description: "Natural vitality without crashes",
    icon: FireIcon,
    color: "from-red-500 to-rose-600",
    details: {
      title: "Sustainable Natural Energy",
      description: "V1 delivers Earth's natural 7.83Hz energy frequency, while Pro version unlocks the full spectrum of energizing frequencies with customizable cycling throughout your day.",
      stats: [
        "89% increased vitality", 
        "24/7 sustained energy",
        "Natural activation"
      ],
      science: "V1: Pure 7.83Hz Earth frequency | Pro: Full spectrum frequency cycling (0.1-999.99Hz)"
    }
  },
  {
    title: "Heart-Brain Sync",
    description: "Achieve peak coherence state",
    icon: HeartIcon,
    color: "from-pink-500 to-rose-600",
    details: {
      title: "Master Your Heart-Brain Connection",
      description: "7.83Hz frequency synchronizes heart and brain rhythms, creating a state of peak performance and emotional balance. Experience the scientifically proven power of coherence.",
      stats: ["78% better emotional balance", "92% increased coherence", "3x faster stress recovery"],
      science: "Heart Rate Variability studies show improved coherence within minutes"
    }
  }
];

// Add this new component inside Product.js
const BenefitCard = ({ benefit, isExpanded, onToggle, expandedBenefit }) => (
  <motion.div
    onClick={onToggle}
    className={`relative cursor-pointer group ${
      isExpanded ? 'col-span-full' : 'col-span-1'
    }`}
    layout
  >
    <motion.div
      className={`relative bg-gradient-to-br from-zinc-900/80 to-black 
        p-4 rounded-xl border border-purple-500/20 
        backdrop-blur-sm overflow-hidden
        ${isExpanded ? 'h-auto' : 'h-[120px]'}`}
      layout
    >
      {/* Background Effects from Home.js */}
      <div className="absolute inset-0 opacity-20">
        <motion.div
          animate={{
            backgroundPosition: ['0% 0%', '100% 100%'],
            opacity: [0.3, 0.6, 0.3],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            ease: "linear"
          }}
          className="absolute inset-0 bg-gradient-to-r from-purple-500/20 via-transparent to-purple-500/20 blur-xl"
          style={{ backgroundSize: '200% 200%' }}
        />
      </div>

      {/* Floating Orb Effect */}
      <motion.div
        animate={{
          y: [-10, 10, -10],
          opacity: [0.5, 0.8, 0.5],
          scale: [1, 1.2, 1],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut"
        }}
        className={`absolute -top-10 -right-10 w-32 h-32 rounded-full blur-2xl bg-gradient-to-r ${benefit.color} opacity-20`}
      />

      {/* Compact View */}
      <motion.div className="flex items-start gap-3 relative z-10" layout>
        <div className={`h-6 w-6 bg-gradient-to-r ${benefit.color} p-1 rounded-lg relative group-hover:scale-110 transition-transform duration-300`}>
          <benefit.icon className="h-full w-full text-white" />
        </div>
        <div>
          <h3 className={`text-sm font-bold bg-gradient-to-r ${benefit.color} bg-clip-text text-transparent`}>
            {benefit.title}
          </h3>
          <p className="text-xs text-gray-400">{benefit.description}</p>
        </div>
      </motion.div>

      {/* Expanded Details */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="mt-4 space-y-4 relative z-10"
          >
            <div className="space-y-2">
              <h4 className="text-lg font-bold text-white">{benefit.details.title}</h4>
              <p className="text-sm text-gray-300">{benefit.details.description}</p>
            </div>

            <div className="grid grid-cols-3 gap-2">
              {benefit.details.stats.map((stat, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-white/5 rounded-lg p-2 text-center hover:bg-white/10 transition-colors duration-300"
                >
                  <p className="text-xs text-white">{stat}</p>
                </motion.div>
              ))}
            </div>

            <div className="text-xs text-gray-400 italic">
              {benefit.details.science}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  </motion.div>
);

// Add this new component
const StickyNav = ({ passedAncestralSection, setPassedAncestralSection }) => {
  return (
    <motion.nav 
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      className="fixed top-16 left-0 right-0 z-40 bg-black/80 backdrop-blur-md border-b border-purple-500/20"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Left side - Logo/Brand */}
          <div className="flex items-center">
            <img 
              src="/favicon-32x32.png" 
              alt="Schumann Logo" 
              className="h-8 w-8"
            />
            <span className="ml-2 text-white font-semibold">The Schumann</span>
          </div>

          {/* Center - Navigation Links */}
          <div className="hidden md:flex items-center space-x-4">
            <button
              onClick={() => document.getElementById('product-options')?.scrollIntoView({ behavior: 'smooth' })}
              className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm"
            >
              Packages
            </button>
            <button
              onClick={() => setPassedAncestralSection(!passedAncestralSection)}
              className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm"
            >
              Learn More
            </button>
          </div>

          {/* Right side - CTA Button */}
          <div>
            <button
              onClick={() => document.getElementById('product-options')?.scrollIntoView({ behavior: 'smooth' })}
              className="bg-gradient-to-r from-purple-600 to-pink-600 text-white px-4 py-2 rounded-lg text-sm font-semibold
                hover:from-purple-700 hover:to-pink-700 transition-all duration-300"
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </motion.nav>
  );
};

// Add new MediaModal component at the top level
const MediaModal = ({ media, onClose }) => {
  const isVideo = media?.endsWith('.mov') || media?.endsWith('.mp4');
  
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
      className="fixed inset-0 bg-black/90 z-50 flex items-center justify-center p-4 backdrop-blur-sm"
    >
      <div className="relative max-w-4xl w-full">
        {isVideo ? (
          <video 
            src={media}
            className="w-full h-auto rounded-lg"
            controls
            autoPlay
            loop
            playsInline
          />
        ) : (
          <img 
            src={media} 
            alt="Expanded view" 
            className="w-full h-auto rounded-lg"
          />
        )}
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-white bg-black/50 rounded-full p-2
                     hover:bg-black/70 transition-colors duration-200"
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
      </div>
    </motion.div>
  );
};

export default function Product() {
  const [selectedOption, setSelectedOption] = useState(null);
  const { addToCart, setIsCartOpen } = useCart();
  const [selectedImage, setSelectedImage] = useState(null);
  const [expandedSection, setExpandedSection] = useState(null);
  const [stockLevels, setStockLevels] = useState({
    1: 14, // Starter Pack
    2: 9,  // Dual Pack
    3: 6   // Family Pack
  });
  const [showStickyButton, setShowStickyButton] = useState(false);
  const [showReviewConfirmation, setShowReviewConfirmation] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [error, setError] = useState('');
  const [remainingStock, setRemainingStock] = useState(100);
  const [passedAncestralSection, setPassedAncestralSection] = useState(false);
  const [ancestralSectionVisible, setAncestralSectionVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [expandedPackage, setExpandedPackage] = useState(null);
  const [expandedFaq, setExpandedFaq] = useState(null);
  const [isFaqSectionExpanded, setIsFaqSectionExpanded] = useState(false);
  const [isComparisonExpanded, setIsComparisonExpanded] = useState(false);
  const [isTechSpecsExpanded, setIsTechSpecsExpanded] = useState(false);
  const [isPackagingExpanded, setIsPackagingExpanded] = useState(false);
  const [isScientificExpanded, setIsScientificExpanded] = useState(false);
  const [expandedReviews, setExpandedReviews] = useState(false);
  const [showReviewForm, setShowReviewForm] = useState(false); // Add this state
  const [expandedBenefit, setExpandedBenefit] = useState(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [showAllBenefits, setShowAllBenefits] = useState(false);
  // State to manage the visibility of the device guidance section
  const [deviceGuidanceExpanded, setDeviceGuidanceExpanded] = useState(false);
  const [showStickyNav, setShowStickyNav] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const navigate = useNavigate();
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [showWaveformInfo, setShowWaveformInfo] = useState(false);

  const packages = [
    {
      id: 1,
      name: 'The Schumann V1',
      subtitle: 'Perfect for Personal Use',
      price: 69.99,
      originalPrice: 99.99,
      savings: 31.00,
      image: '/Designer(24).jpg',
      features: [
        'Fixed 7.83Hz frequency',
        '190ft² protection radius',
        '12+ hours battery life',
        'Perfect for personal use'
      ],
      idealFor: [
        'Home use',
        'Personal wellness',
        'Sleep improvement'
      ]
    },
    {
      id: 2, // Matches Cart.js product 2
      name: 'Dual Pack',
      subtitle: 'Protect Two Places',
      price: 99.98,
      originalPrice: 200.00,
      savings: 100.01,
      features: [
        'Two Schumann 7.83 devices',
        'Extended coverage area',
        'Perfect for multi-room setup',
        'Free EMF Protection Guide eBook'
      ]
    },
    {
      id: 3, // Matches Cart.js product 3
      name: 'Family Pack',
      subtitle: 'Protect Your Entire Home',
      price: 129.99,
      originalPrice: 300.00,
      savings: 170.01,
      features: [
        'Three Schumann 7.83 devices',
        'Whole-home coverage',
        'Maximum family protection',
        'Free EMF Protection Guide eBook'
      ]
    },
    {
      id: 4, // Matches Cart.js product 4
      name: 'Schumann V2 Pro',
      subtitle: 'Professional Grade Device',
      price: 169.00,
      originalPrice: 200.00,
      savings: 31.00,
      features: [
        'Adjustable frequency (0.1-999.99 Hz)',
        '250ft² enhanced protection radius',
        '72+ hours extended battery life',
        'Digital display & controls'
      ],
      technicalSpecs: {
        core: [
          'Multi-frequency (0.1 - 999.99 Hz)',
          'Waveform switching (sine/square)',
          'Digital precision (±0.01 Hz accuracy)',
          '2x stronger field generation'
        ],
        connectivity: [
          'USB-C Charging Port',
          'LED Status Indicators',
          'Touch Controls',
          'Auto Sleep Mode'
        ]
      }
    }
  ];

  const productOptions = [
    {
      id: 1,
      price: 69.99,
      originalPrice: 99.99,
      quantity: 1,
      savings: 30.00,
      features: [
        "Fixed 7.83Hz frequency",
        "190ft² protection radius",
        "12+ hours battery life",
        "Perfect for personal use"
      ]
    },
    {
      id: 4,
      price: 169.00,
      originalPrice: 200.00,
      quantity: 1,
      savings: 41.00,
      features: [
        "Adjustable frequency (0.1-999.99 Hz)",
        "250ft² enhanced protection radius",
        "72+ hours extended battery life",
        "Digital display & controls",
        "Professional-grade features",
        "Perfect for practitioners & clinics"
      ]
    }
  ];

  const product = {
    name: "Schumann Resonance Device",
    description: "Experience the natural frequency of Earth for better sleep and wellness",
    features: [
      "7.83 Hz Frequency Generation",
      "Sleep Enhancement Mode",
      "EMF Harmonization",
      "Portable Design",
      "12-Hour Battery Life"
    ]
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    handleAddToCart(option);
    
    // Update stock level for the selected option
    setStockLevels(prev => ({
      ...prev,
      [option.id]: Math.max(prev[option.id] - 1, option.id === 1 ? 13 : 3)
    }));
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const handleCloseZoom = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Get the Family Pack section element
      const familyPackSection = document.getElementById('family-pack-section');
      if (familyPackSection) {
        const rect = familyPackSection.getBoundingClientRect();
        // Show button after scrolling past the section
        setShowStickyButton(rect.bottom < 0);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Add this function with your other functions
  const updateStock = () => {
    const currentStock = parseInt(localStorage.getItem('remainingStock')) || 100;
    const decrease = Math.floor(Math.random() * 3); // Random decrease between 0-2
    const newStock = Math.max(currentStock - decrease, 7); // Never go below 7
    localStorage.setItem('remainingStock', newStock.toString());
    setRemainingStock(newStock);
  };

  // Optional: Add this to your addToCart function to decrease stock when items are added
  const handleAddToCart = (option) => {
    addToCart(option);
    // Decrease stock by the quantity purchased
    setRemainingStock(prevStock => {
      const newStock = Math.max(prevStock - option.quantity, 7);
      localStorage.setItem('remainingStock', newStock.toString());
      return newStock;
    });
  };

  // Add this useEffect after your other useEffect hooks
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDiscount(true);
    }, 80000); // 80 seconds

    return () => clearTimeout(timer);
  }, []);

  // Add this function with your other handlers
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      const response = await fetch('https://connect.mailerlite.com/api/subscribers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiYjk4ZmZlNGMwM2VjNWQ2NjA0OTk5Y2JmOWVmMzNlYTJiZWI5N2YyNWY1MTNjYTkwOTRhNDk0N2MzMDNiNzQyMzVhZmY2YWI0MmNmMGQwNzkiLCJpYXQiOjE3MzE5Njg4MjguNDYzMzU1LCJuYmYiOjE3MzE5Njg4MjguNDYzMzU3LCJleHAiOjQ4ODc2NDI0MjguNDU5NDQ3LCJzdWIiOiIxMTkzMjcyIiwic2NvcGVzIjpbXX0.jlv3EMuvlqTZ3GXxHAgOdEL9TqMtyUkmmdKaRikwEi03izMl_1Tvg5g-sWUV39KYKx9DsAX2H24PjBLSJhb8ceYAtvhjlnIW06-ELXdyY7dHhzNa37rZe2IzCbj423NxcQDixVFdCvXr7iyZbsO0hu0wNlItDa5MTOn_RhLN229kR-Ab1ExUFPZtHuH8FOcgZjfHP0dVc_MXF0iXt1uD-Kd5LxfhhUTMrBpEZFaF0cEefL6-9bfiCUbHI0Zbd0E6vbby1ZHRVZkWc47F688RbBM1SGZ-j99nx-3ZpRoU-aU_u2Y443BEnlA-wlMGh_6-Mk0ctte8lf37AstWf7Sy37E50LthvI1as3673hraoJ0rl-v_FgujannHglcsJYCrDbaXDYiOSvFgzjQRXjTr7ln3b0FFaiBwOExNFKiyjr8LABEzWSHM-cjXzb1UvoNt5IvZC8CEP1VC1DZaGDDDNxjVgwKWX0yTUdPMWa64wyyiziJFlJi1hxd1qDXgHXbF59XUWCp1ID8Utuweli-XwD4cyJgfmlcx9ZqlnNzymmqeUMPrADHKKom091BWzoGz8dDs3iI1o1c-GJyF7NzLc6BCzdRXtMajbH3Eryd0XLWCv8YDHqH8kKzLnB_6Ky_3X26liid_PTpIShCU40MsAzw-PF2-ea8l1lkP-qMeCmM'
        },
        body: JSON.stringify({
          email: email,
          groups: ['138265391280424210'],
          status: 'active',
          subscribed: true,
          fields: {
            name: 'Discount Code Subscriber',
            source: 'Product Page Popup'
          }
        })
      });

      if (response.ok) {
        setShowCode(true);
      } else {
        setError('Something went wrong. Please try again.');
      }
    } catch (err) {
      setError('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Add this effect to create a decreasing counter
  useEffect(() => {
    // Get stored stock from localStorage or set initial value
    const storedStock = localStorage.getItem('remainingStock');
    const initialStock = storedStock ? parseInt(storedStock) : 100;
    setRemainingStock(initialStock);

    // Decrease stock randomly every few minutes
    const decreaseInterval = setInterval(() => {
      setRemainingStock(prevStock => {
        const newStock = Math.max(prevStock - Math.floor(Math.random() * 3), 7);
        localStorage.setItem('remainingStock', newStock.toString());
        return newStock;
      });
    }, 180000); // Updates every 3 minutes

    return () => clearInterval(decreaseInterval);
  }, []);

  // Add this modal component
  const ImageModal = ({ image, onClose }) => (
    <div 
      className="fixed inset-0 bg-black/80 z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div className="relative max-w-4xl w-full">
        <img 
          src={image} 
          alt="Expanded view" 
          className="w-full h-auto rounded-lg"
        />
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-white bg-black/50 rounded-full p-2"
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
      </div>
    </div>
  );

  // Add this useEffect to handle scroll detection
  useEffect(() => {
    const handleScroll = () => {
      const ancestralSection = document.querySelector('#ancestral-section');
      if (ancestralSection) {
        const rect = ancestralSection.getBoundingClientRect();
        // Check if the section is in view
        const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;
        setAncestralSectionVisible(isVisible);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const ancestralSection = document.querySelector('#ancestral-section');
      if (ancestralSection) {
        const rect = ancestralSection.getBoundingClientRect();
        // If section has come into view at any point, show the button
        if (rect.top <= window.innerHeight) {
          setShowStickyButton(true);
        } else {
          setShowStickyButton(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % productImages.length);
  };

  const previousImage = () => {
    setCurrentImageIndex((prev) => (prev - 1 + productImages.length) % productImages.length);
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;

    if (isLeftSwipe) {
      nextImage();
    }
    if (isRightSwipe) {
      previousImage();
    }

    // Reset values
    setTouchStart(0);
    setTouchEnd(0);
  };

  // Add this near the top of your component, after your other button handlers
  const handleQuickBuy = (productId) => {
    const option = productOptions.find(opt => opt.id === productId);
    if (option) {
      handleAddToCart(option);
      setIsCartOpen(true);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      // Show button after scrolling past 300px
      setShowScrollButton(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const productSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "The Schumann Pro V2 | Premium 7.83 Hz Schumann Resonance Generator",
    "description": "World's most advanced Schumann resonance device (0.1-999.99Hz). Features NASA-inspired technology, EMF protection, and 120hr battery life. Experience the Earth's natural frequency for enhanced sleep, meditation, and wellness. Trusted by over 15,000+ customers worldwide.",
    "sku": "SCHM-PRO-V2-2024",
    "mpn": "SCHPROV2-2024",
    "category": "Health/Wellness/Schumann Resonance Devices",
    "brand": {
      "@type": "Brand",
      "name": "The Schumann",
      "logo": "https://theschumann.com/brand-logo.png",
      "slogan": "Earth's Natural Frequency for Optimal Wellness",
      "description": "Pioneer in Schumann resonance technology since 2020"
    },
    "image": [
      {
        "@type": "ImageObject",
        "url": "https://theschumann.com/product-front.jpg",
        "caption": "The Schumann Pro V2 - Premium 7.83 Hz Generator",
        "description": "Professional grade Schumann resonance device with multi-frequency capabilities"
      },
      {
        "@type": "ImageObject",
        "url": "https://theschumann.com/product-side.jpg",
        "caption": "The Schumann Device Side Profile",
        "description": "Premium build quality with EMF protection technology"
      }
    ],
    "offers": {
      "@type": "AggregateOffer",
      "lowPrice": "159.00",
      "highPrice": "299.00",
      "priceCurrency": "USD",
      "availability": "https://schema.org/InStock",
      "seller": {
        "@type": "Organization",
        "name": "The Schumann",
        "sameAs": [
          "https://facebook.com/TheSchumann",
          "https://instagram.com/TheSchumann",
          "https://youtube.com/TheSchumann"
        ]
      },
      "priceValidUntil": "2024-12-31",
      "warranty": "2-year manufacturer warranty"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "reviewCount": "15420",
      "bestRating": "5",
      "worstRating": "1"
    },
    "awards": [
      "Best Schumann Resonance Device 2024",
      "Top Wellness Innovation Award"
    ],
    "hasMerchantReturnPolicy": {
      "@type": "MerchantReturnPolicy",
      "applicableCountry": "US",
      "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
      "merchantReturnDays": 30,
      "returnMethod": "https://schema.org/ReturnByMail",
      "returnFees": "https://schema.org/FreeReturn"
    },
    "additionalProperty": [
      {
        "@type": "PropertyValue",
        "name": "Primary Frequency",
        "value": "7.83 Hz (Schumann Resonance)"
      },
      {
        "@type": "PropertyValue",
        "name": "Frequency Range",
        "value": "0.1-999.99 Hz"
      },
      {
        "@type": "PropertyValue",
        "name": "Battery Life",
        "value": "120 hours"
      },
      {
        "@type": "PropertyValue",
        "name": "Technology",
        "value": "Patented WaveSwitching™"
      }
    ],
    "isRelatedTo": [
      {
        "@type": "Product",
        "name": "The Schumann V1",
        "description": "Classic 7.83 Hz Schumann resonance generator"
      }
    ],
    "review": reviews.map(review => ({
      "@type": "Review",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5",
        "bestRating": "5"
      },
      "author": {
        "@type": "Person",
        "name": review.name
      },
      "datePublished": review.date,
      "reviewBody": review.review,
      "publisher": {
        "@type": "Organization",
        "name": "The Schumann"
      }
    }))
  };

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is the Schumann resonance and why is it important?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The Schumann resonance (7.83 Hz) is Earth's natural frequency, discovered by physicist W.O. Schumann. This frequency is vital for human well-being, sleep cycles, and cellular regeneration. Our device generates this exact frequency for optimal wellness."
      }
    },
    {
      "@type": "Question",
      "name": "How does The Schumann Pro V2 compare to other devices?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The Schumann Pro V2 is the only device featuring patented WaveSwitching technology, true electromagnetic field generation (not audio), and professional-grade frequency control (0.1-999.99Hz). It includes EMF protection, 120hr battery life, and premium build quality."
      }
    },
    {
      "@type": "Question",
      "name": "Is there scientific evidence supporting Schumann resonance therapy?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, extensive research including NASA studies has shown the importance of Earth's natural frequencies for human health. Our technology is based on decades of scientific research and validated by thousands of satisfied customers worldwide."
      }
    }]
  };

  const handleBenefitToggle = (index) => {
    setExpandedBenefit(expandedBenefit === index ? null : index);
  };

  // Animation variants for the collapsible section
  const variants = {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: 'auto' }
  };

  // Add this modal component for image zoom
  const ImageZoomModal = ({ image, onClose }) => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/90 backdrop-blur-sm"
    >
      <motion.div 
        className="relative max-w-7xl w-full"
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.9 }}
      >
        <motion.img
          src={image}
          alt="Zoomed Review"
          className="w-full h-auto rounded-lg"
          layoutId={`zoom-${image}`}
        />
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
      </motion.div>
    </motion.div>
  );

 
  return (
    <div className="min-h-screen bg-black text-white">
      {/* Banner - Adjusted spacing */}
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-gradient-to-r from-black via-[#B4833E] to-black border-b border-[#B4833E]/20 mt-16"
      >
        <div className="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8"> {/* Reduced py-3 to py-2 */}
          <div className="text-center flex items-center justify-center space-x-2">
            <span className="text-white font-bold">PRO V2 MULTI-FREQUENCY</span>
            <span className="bg-gradient-to-r from-[#B4833E] via-white to-[#B4833E] text-transparent bg-clip-text font-bold">
              RELEASED
            </span>
          </div>
        </div>
      </motion.div>

      {/* Reduced top padding */}
      <div className="pt-0 sm:pt-0"> {/* Changed from pt-8 sm:pt-12 */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
          <div className="lg:grid lg:grid-cols-3 lg:gap-x-8">
            {/* Left Column - Product Images */}
            <div className="lg:col-span-1 relative">
              {/* Main Carousel */}
              <div className="relative aspect-w-3 aspect-h-2 rounded-lg overflow-hidden">
                <div
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                >
                  <img
                    src={productImages[currentImageIndex].src}
                    alt={productImages[currentImageIndex].alt}
                    className="w-full h-full object-center object-cover cursor-zoom-in"
                    onClick={() => setSelectedImage(productImages[currentImageIndex].src)}
                  />
                </div>
                
                {/* Navigation Arrows - Updated positioning and size */}
                <div className="absolute inset-0 flex items-center justify-between px-2" style={{ top: '83%' }}>
                  <button
                    onClick={previousImage}
                    className="p-2 rounded-full bg-black/30 text-white/80 
                              hover:bg-black/50 hover:text-white
                              transform hover:scale-110 hover:-translate-x-1
                              transition-all duration-300 ease-out
                              backdrop-blur-sm border border-white/10
                              group"
                    aria-label="Previous image"
                  >
                    <ChevronLeftIcon className="h-4 w-4 group-hover:stroke-2" />
                  </button>
                  <button
                    onClick={nextImage}
                    className="p-2 rounded-full bg-black/30 text-white/80 
                              hover:bg-black/50 hover:text-white
                              transform hover:scale-110 hover:translate-x-1
                              transition-all duration-300 ease-out
                              backdrop-blur-sm border border-white/10
                              group"
                    aria-label="Next image"
                  >
                    <ChevronRightIcon className="h-4 w-4 group-hover:stroke-2" />
                  </button>
                </div>
              </div>

              {/* Thumbnail Preview */}
              <div className="hidden sm:grid grid-cols-3 gap-4 mt-4">
                {productImages.map((image, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentImageIndex(index)}
                    className={`relative rounded-lg overflow-hidden ${
                      currentImageIndex === index ? 'ring-2 ring-purple-500' : ''
                    }`}
                  >
                    <img
                      src={image.src}
                      alt={`Thumbnail ${index + 1}`}
                      className="w-full h-20 object-cover cursor-pointer hover:opacity-80 transition-opacity duration-300"
                    />
                  </button>
                ))}
              </div>
          

{/* Quick Shop Buttons */}
<div className="flex justify-center gap-4 mt-6">
  <motion.button
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5, delay: 0.2 }}
    onClick={() => {
      setExpandedPackage(1); // Set expanded package first
      setTimeout(() => {
        document.getElementById('product-options')?.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }, 100); // Small delay to allow expansion
    }}
    className="px-6 py-3 bg-gradient-to-r from-purple-600 to-black text-white 
      font-semibold rounded-lg hover:from-purple-700 hover:to-gray-900 
      transition-all duration-300 transform hover:scale-105
      flex items-center gap-2"
  >
    <span>Buy 7.83Hz V1</span>
  </motion.button>
  <motion.button
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5, delay: 0.3 }}
    onClick={() => {
      setExpandedPackage(4);
      setTimeout(() => {
        const element = document.getElementById('product-options');
        if (element) {
          const offset = element.offsetTop + 420; // Increased from 100 to 300 to scroll lower
          window.scrollTo({
            top: offset,
            behavior: 'smooth'
          });
        }
      }, 100);
    }}
    className="px-6 py-3 bg-white font-semibold rounded-lg 
      hover:bg-gray-100 transition-all duration-300 transform hover:scale-105
      border border-gray-200 flex items-center gap-2"
  >
    <span className="bg-gradient-to-r from-[#FFD700] to-[#B8860B] bg-clip-text text-transparent">Go Pro V2</span>
  </motion.button>
</div>


            </div>

            {/* Right Column - Product Info */}
            <div className="lg:col-span-2 mt-8 sm:mt-0">
              {/* Hero Heading */}
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="text-center space-y-4 mb-12"
              >
<motion.h2 
  className="text-2xl sm:text-3xl md:text-4xl font-bold tracking-tight"
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.3 }}
>
  <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-purple-200 to-white block mb-2">
    One Device,
  </span>
  <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600 block">
    Many Benefits
  </span>
</motion.h2>

<motion.p
  className="text-gray-400 text-base sm:text-lg max-w-2xl mx-auto mt-6"
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  transition={{ delay: 0.4 }}
>
  Experience the power of Earth's natural frequency in one elegant device
</motion.p>



{/* Added Click Instruction */}

<motion.div
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  transition={{ delay: 0.5 }}
  className="flex justify-center mt-3"
>
  <div className="relative inline-flex items-center gap-2 px-4 py-2 rounded-full bg-purple-500/10">
    <span className="absolute inset-0 bg-purple-500/20 rounded-full blur-sm animate-pulse" />
    <ChevronDownIcon 
      className="h-4 w-4 text-purple-400 animate-bounce rotate-90" 
    />
    <span className="relative text-sm text-purple-400">
      Click benefits to explore
    </span>
  </div>
</motion.div>
              
{/* Enhanced Benefits Grid with Dynamic Animations */}

<motion.div 
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  transition={{ duration: 0.5 }}
  className="relative"
>
  {/* Animated Background Elements */}
  <div className="features-section"></div>
  <div className="absolute inset-0 overflow-hidden">
    <motion.div
      animate={{
        scale: [1, 1.2, 1],
        opacity: [0.1, 0.2, 0.1],
      }}
      transition={{
        duration: 8,
        repeat: Infinity,
        ease: "easeInOut"
      }}
      className="absolute top-1/2 left-1/4 -translate-x-1/2 -translate-y-1/2 
        w-[500px] h-[500px] 
        bg-purple-500/20 rounded-full blur-[100px]"
    />
    <motion.div
      animate={{
        scale: [1.2, 1, 1.2],
        opacity: [0.1, 0.2, 0.1],
      }}
      transition={{
        duration: 10,
        repeat: Infinity,
        ease: "easeInOut",
        delay: 1
      }}
      className="absolute top-1/2 right-1/4 translate-x-1/2 -translate-y-1/2 
        w-[600px] h-[600px] 
        bg-blue-500/20 rounded-full blur-[120px]"
    />
  </div>

  {/* New Benefits Grid */}

  <motion.div 
    className="grid grid-cols-2 sm:grid-cols-3 gap-3 max-w-4xl mx-auto my-8 relative z-10 px-4"
    layout
  >
    {benefits.map((benefit, index) => (
      <BenefitCard
        key={index}
        benefit={benefit}
        isExpanded={expandedBenefit === index}
        onToggle={() => handleBenefitToggle(index)}
        expandedBenefit={expandedBenefit}
      />
    ))}
  </motion.div>

 
 
</motion.div>
               {/* New Lost Connection Section */}
<motion.div 
  className="mt-8"
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 0.5, ease: "easeOut" }}
>
  {/* For the Nature Feels Like Home button */}
  <button
    onClick={() => setPassedAncestralSection(!passedAncestralSection)}
    className="w-full bg-gradient-to-r from-emerald-900/80 to-green-900/80 backdrop-blur-sm rounded-xl px-6 py-4 
            border border-emerald-400/30 flex justify-between items-center 
            hover:from-emerald-800/90 hover:to-green-800/90 
            transition-all duration-300 transform hover:scale-[1.02]
            group shadow-lg hover:shadow-emerald-500/20"
  >
    <div className="text-left">
      <h4 className="text-xl font-bold text-white group-hover:text-emerald-200 transition-colors">
        Why Nature Feels Like Coming Home
      </h4>
      <span className="text-sm text-emerald-300/80 block mt-1 group-hover:text-emerald-200 transition-colors">
        Our mission and how we got here →
      </span>
    </div>
    <motion.div
      animate={{
        rotate: passedAncestralSection ? 720 : 0,
      }}
      transition={{ duration: 0.6, ease: "anticipate" }}
    >
      <SparklesIcon className="w-6 h-6 text-emerald-400 group-hover:text-emerald-200 transition-colors" />
    </motion.div>
  </button>

                  <AnimatePresence mode="wait">
                    {passedAncestralSection && (
                      <motion.div 
                        initial={{ opacity: 0, height: 0, y: -20 }}
                        animate={{ 
                          opacity: 1, 
                          height: "auto", 
                          y: 0,
                          transition: {
                            height: {
                              duration: 0.4,
                              ease: "easeOut"
                            },
                            opacity: {
                              duration: 0.3,
                              delay: 0.1
                            }
                          }
                        }}
                        exit={{ 
                          opacity: 0,
                          height: 0,
                          y: -20,
                          transition: {
                            height: {
                              duration: 0.3
                            },
                            opacity: {
                              duration: 0.2
                            }
                          }
                        }}
                        className="mt-4 bg-black/40 backdrop-blur-sm rounded-xl overflow-hidden"
                      >
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ 
                            opacity: 1,
                            transition: { delay: 0.2, duration: 0.3 }
                          }}
                          className="p-6 border border-purple-500/20"
                        >
                          {/* Content remains the same */}
                          <div className="space-y-8">
                            {/* Single Hero Image with Updated Title */}
                            <div className="relative group overflow-hidden rounded-xl">
                              <img 
                                src="/blog/lifestyle/daily-rhythm.jpg"
                                alt="The Lost Connection"
                                className="w-full h-[300px] object-cover transform transition-transform duration-500 group-hover:scale-110"
                              />
                              <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/30 to-transparent" />
                              <div className="absolute bottom-4 left-4 right-4">
                                <h4 className="text-2xl font-bold bg-gradient-to-r from-purple-400 to-pink-600 bg-clip-text text-transparent mb-1">
                                  The Lost Connection
                                </h4>
                            
                              </div>
                            </div>

                            {/* Content Section */}
                            <div className="space-y-6 mt-8">
                              {/* Quote with special styling */}
                              <div className="text-lg text-gray-300 italic border-l-4 border-purple-500/50 pl-4 py-2">
                                "Have you ever noticed how a simple walk on the beach feels more rejuvenating than a week's vacation? There's a profound scientific reason for this..."
                              </div>

                              {/* Birthright Section */}
                              <div className="bg-purple-900/20 backdrop-blur-sm rounded-xl p-6 border border-purple-500/20">
                                <h5 className="text-xl font-semibold text-purple-300 mb-4">
                                  Our Forgotten Birthright
                                </h5>
                                
                                <p className="text-gray-300 mb-4">
                                  For 99.99% of human existence, we lived in perfect resonance with Earth's 7.83 Hz frequency.
                                </p>

                                <ul className="space-y-3">
                                  {[
                                    "Slept under stars, bathed in natural frequencies",
                                    "Maintained Earth's healing pulse connection",
                                    "Lived in harmony with nature's rhythms and cycles",
                                    "Experienced deep cellular regeneration daily"
                                  ].map((item, index) => (
                                    <li key={index} className="flex items-center gap-3 text-gray-300">
                                      <span className="text-purple-400">✧</span>
                                      {item}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>

                            {/* The Modern Crisis Section */}
                            <div className="space-y-8 text-center"> {/* Increased spacing */}
                              <div className="space-y-2">
                                <h4 className="text-3xl font-bold animate-pulse flex items-center justify-center gap-3">
                                  <span className="text-red-500 text-4xl">⚠</span>
                                  <span className="bg-gradient-to-r from-red-500 via-red-400 to-red-500 bg-clip-text text-transparent">
                                    The Modern Crisis
                                  </span>
                                  <span className="text-red-500 text-4xl">⚠</span>
                                </h4>
                                <p className="text-gray-400 text-lg max-w-2xl mx-auto">
                                  Our modern world has created an unprecedented disconnect from Earth's natural frequency
                                </p>
                              </div>
                              
                              {/* Crisis Points - Enhanced */}
                              <div className="grid gap-4 max-w-2xl mx-auto bg-black/30 rounded-xl p-6 border border-red-500/20">
                                <div className="text-gray-300 transform hover:scale-105 transition-all duration-300 p-3 rounded-lg bg-gradient-to-r from-red-900/20 to-red-900/10">
                                  5G networks & artificial frequencies flooding our environment
                                </div>
                                <div className="text-gray-300 transform hover:scale-105 transition-all duration-300 p-3 rounded-lg bg-gradient-to-r from-red-900/20 to-red-900/10">
                                  24/7 WiFi exposure disrupting our natural rhythms
                                </div>
                                <div className="text-gray-300 transform hover:scale-105 transition-all duration-300 p-3 rounded-lg bg-gradient-to-r from-red-900/20 to-red-900/10">
                                  93% indoor time, severing our connection to Earth's healing frequency
                                </div>
                                <div className="text-gray-300 transform hover:scale-105 transition-all duration-300 p-3 rounded-lg bg-gradient-to-r from-red-900/20 to-red-900/10">
                                  Chronic stress, sleep issues, and health problems reaching epidemic levels
                                </div>
                                <div className="text-gray-300 transform hover:scale-105 transition-all duration-300 p-3 rounded-lg bg-gradient-to-r from-red-900/20 to-red-900/10">
                                  Weakened immune systems from constant frequency interference
                                </div>
                              </div>

                              {/* Technology Impact Image */}
                              <div className="relative mt-8 group overflow-hidden rounded-xl max-w-3xl mx-auto">
                                <img 
                                  src="/blog/technology/wearable-tech.jpg"
                                  alt="Modern Technology Impact"
                                  className="w-full h-[300px] object-cover transform transition-transform duration-500 group-hover:scale-105"
                                />
                                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent" />
                                <div className="absolute bottom-4 left-4 right-4">
                                  <h5 className="text-xl font-bold text-white mb-2">The Digital Dilemma</h5>
                                  <p className="text-sm text-gray-200 max-w-xl mx-auto">
                                    While technology advances, our bodies remain tuned to Earth's natural frequency. 
                                    This disconnect is the root of many modern health challenges.
                                  </p>
                                </div>
                              </div>
                            </div>

                            {/* Nature's Power */}
                            <div className="space-y-6 mt-12">
                              <div className="text-center space-y-3">
                                <h4 className="text-2xl font-bold bg-gradient-to-r from-emerald-400 to-teal-500 bg-clip-text text-transparent">
                                  The Profound Power of Nature's Frequency
                                </h4>
                                <p className="text-gray-400 text-lg max-w-2xl mx-auto">
                                  Experience the healing resonance that nature provides
                                </p>
                              </div>

                              {/* Nature Experiences Grid */}
                              <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 max-w-3xl mx-auto">
                                {[
                                  { emoji: "🌊", text: "Beach calm", desc: "Waves matching Earth's pulse" },
                                  { emoji: "🌲", text: "Forest peace", desc: "Natural frequency sanctuary" },
                                  { emoji: "⛰️", text: "Mountain clarity", desc: "Elevated consciousness" },
                                  { emoji: "🌅", text: "Sunset peace", desc: "Daily frequency reset" },
                                  { emoji: "🌌", text: "Starlit rest", desc: "Cosmic alignment" },
                                  { emoji: "🦋", text: "Garden alive", desc: "Living frequency dance" }
                                ].map((item, index) => (
                                  <div 
                                    key={index}
                                    className="bg-gradient-to-br from-emerald-900/30 to-teal-900/20 
                                       backdrop-blur-sm rounded-xl p-4 
                                       border border-emerald-500/20
                                       transform hover:scale-105 transition-all duration-300
                                       group"
                                  >
                                    <div className="text-4xl mb-2 group-hover:scale-110 transition-transform duration-300">
                                      {item.emoji}
                                    </div>
                                    <div className="text-emerald-300 font-medium mb-1">
                                      {item.text}
                                    </div>
                                    <div className="text-xs text-gray-400">
                                      {item.desc}
                                    </div>
                                  </div>
                                ))}
                              </div>

                              {/* Quote Section */}
                              <div className="max-w-2xl mx-auto mt-8 text-center">
                                <div className="relative">
                                  <div className="absolute -top-4 -left-4 text-4xl text-emerald-500/20">
                                    "
                                  </div>
                                  <p className="text-lg text-gray-300 italic px-8">
                                    These aren't just experiences – they're your body reconnecting with its natural frequency.
                                  </p>
                                  <div className="absolute -bottom-4 -right-4 text-4xl text-emerald-500/20">
                                    "
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </motion.div>
                      </motion.div>
                    )}
                  </AnimatePresence>
  {/* Spacer for previous section */}
  <div className="py-6 sm:py-12"></div>

{/* Feel It Trust It Live It Section */}
<div className="relative w-full h-full mb-16 sm:mb-24">
  <img
    src="/TrustIt.webp"
    alt="Feel it, Trust it, Live it - The Schumann"
    className="w-full h-auto rounded-lg shadow-2xl"
  />
</div>

                  
                  <motion.div 

              
  className="relative py-8 sm:py-16 mt-6 sm:mt-12"
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
>
  <div className="relative z-10 max-w-6xl mx-auto px-4">
    {/* Main Content Grid */}
    <div className="grid md:grid-cols-2 gap-4 sm:gap-8 items-center">
      {/* Left Column - Story */}
      <motion.div 
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.2 }}
        className="space-y-4 sm:space-y-6"
      >
        <h2 className="text-2xl sm:text-4xl font-bold leading-tight">
          <span className="bg-gradient-to-r from-purple-400 to-pink-600 bg-clip-text text-transparent">
            Premium Technology,
          </span>
          <span className="text-white"> For Everyone</span>
        </h2>

        <div className="space-y-2 sm:space-y-4">
          {/* Price Comparison - Compact Mobile */}
          <div className="grid grid-cols-1 gap-2 text-sm sm:text-base">
            <div className="flex items-center gap-2 bg-black/20 p-2 rounded-lg">
              <XMarkIcon className="h-4 w-4 text-purple-500 shrink-0" />
              <p className="text-gray-400">
                <span className="line-through text-gray-500">$1500-2000</span>
                <span className="ml-1">Traditional devices</span>
              </p>
            </div>
            <div className="flex items-center gap-2 bg-black/20 p-2 rounded-lg">
              <XMarkIcon className="h-4 w-4 text-purple-500 shrink-0" />
              <p className="text-gray-400">
                <span className="line-through text-gray-500">Complex Technical DIY</span>
                <span className="ml-1">Hours of setup</span>
              </p>
            </div>
            <div className="flex items-center gap-2 bg-black/20 p-2 rounded-lg">
              <CheckCircleIcon className="h-4 w-4 text-green-500 shrink-0" />
              <p className="text-white">
                <span className="font-bold">From $69.99</span>
                <span className="ml-1">Ready to use</span>
              </p>
            </div>
          </div>
        </div>

        <p className="text-sm sm:text-lg text-gray-300">
          Everyone deserves access to Earth's natural frequency. 
          It's your birthright, not a luxury.
        </p>

        <motion.button
  whileHover={{ scale: 1.05 }}
  whileTap={{ scale: 0.95 }}
  onClick={() => {
    document.getElementById('product-options')?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  }}
  className="w-full sm:w-auto px-4 sm:px-8 py-3 sm:py-4 bg-gradient-to-r from-purple-600 to-pink-600 
    rounded-lg font-semibold text-white shadow-lg text-sm sm:text-base
    hover:from-purple-700 hover:to-pink-700 transition-all duration-300"
>
  Claim Your Birthright Today
</motion.button>
      </motion.div>

      {/* Right Column - Visual */}
      <motion.div 
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.4 }}
        className
        ="relative mt-4 sm:mt-0"
      >
        <div className="bg-gradient-to-br from-purple-900/20 to-pink-900/20 
          backdrop-blur-sm rounded-xl p-3 sm:p-6 border border-purple-500/20"
        >
          {/* Stats Grid - Compact for Mobile */}
          <div className="grid grid-cols-2 gap-2 sm:gap-4 text-center">
            {[
              { number: "10K+", label: "Customers" },
              { number: "4.9/5", label: "Rating" },
              { number: "60%", label: "Savings" },
              { number: "24/7", label: "Support" }
            ].map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 + (index * 0.1) }}
                className="p-2 sm:p-4 bg-black/20 rounded-lg border border-purple-500/10"
              >
                <div className="text-lg sm:text-2xl font-bold bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
                  {stat.number}
                </div>
                <div className="text-xs sm:text-sm text-gray-400">
                  {stat.label}
                </div>
              </motion.div>
            ))}
          </div>

          {/* Testimonial - Compact for Mobile */}
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
            className="mt-3 sm:mt-6 p-3 sm:p-4 bg-black/40 rounded-lg border border-purple-500/10"
          >
            <p className="text-gray-300 italic text-xs sm:text-sm">
              "The Schumann made this technology accessible and changed my life. Now my whole family uses it."
            </p>
            <p className="text-purple-400 text-xs sm:text-sm mt-1 sm:mt-2">
              - Carmen S., Lawyer
            </p>
          </motion.div>
        </div>
      </motion.div>
    </div>
  </div>
</motion.div>

                </motion.div>
              </motion.div>

              {/* Package Options - Added right after benefits */}
              <motion.div 
                id="product-options"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
                className="mt-16"
              >
                <motion.h2 
  className="text-3xl sm:text-4xl md:text-5xl font-bold mx-auto max-w-[95%] sm:max-w-none text-center mb-4"
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.2 }}
>
  <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-purple-200 to-white block mb-2">
    Choose Your
  </span>
  <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600 block">
    Package
  </span>
</motion.h2>

                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.3 }}
                  className="text-gray-400 text-base sm:text-lg max-w-2xl mx-auto text-center mb-8"
                >
                  No sound. No cables. No tricks. Just pure frequency.
                </motion.p>
{/* New Waveform Explanation Button & Container */}
<div className="max-w-2xl mx-auto mb-8">
  <motion.button
    onClick={() => setShowWaveformInfo(!showWaveformInfo)}
    className="flex items-center justify-center gap-2 mx-auto px-4 py-2 
      bg-purple-500/10 hover:bg-purple-500/20 
      rounded-full border border-purple-500/20
      text-sm text-purple-300 transition-all duration-300
      group"
  >
    <motion.div
      animate={{
        rotate: showWaveformInfo ? 180 : 0
      }}
      transition={{ duration: 0.3 }}
    >
      <ChevronDownIcon className="h-4 w-4" />
    </motion.div>
    <span>How do we use frequencies without sound?</span>
  </motion.button>

  <AnimatePresence>
    {showWaveformInfo && (
      <motion.div
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 0.3 }}
        className="overflow-hidden"
      >
        <div className="mt-4 p-6 bg-purple-900/20 backdrop-blur-sm rounded-xl border border-purple-500/20">
          {/* Waveform Animation */}
          <div className="mb-4 bg-black/40 p-4 rounded-lg border border-purple-500/20">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm text-cyan-300">Pure Frequency Output:</span>
              <motion.div
                animate={{ opacity: [1, 0.5, 1] }}
                transition={{ duration: 2, repeat: Infinity }}
                className="text-xs text-cyan-400"
              >
                Active
              </motion.div>
            </div>
            <div className="relative h-16 bg-black/60 rounded-md overflow-hidden">
              <motion.div
                className="absolute inset-0 flex items-center justify-center"
                animate={{
                  x: [-20, 0],
                  opacity: [0.5, 1, 0.5]
                }}
                transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
              >
                <svg width="100%" height="40" viewBox="0 0 200 40">
                  <motion.path
                    d="M 0 20 Q 25 0, 50 20 T 100 20 T 150 20 T 200 20"
                    fill="none"
                    stroke="url(#gradient)"
                    strokeWidth="2"
                  />
                  <defs>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop offset="0%" stopColor="#9333EA" />
                      <stop offset="100%" stopColor="#EC4899" />
                    </linearGradient>
                  </defs>
                </svg>
              </motion.div>
            </div>
          </div>

          {/* Explanation Text */}
          <div className="space-y-4">
            <p className="text-gray-300">
              Unlike traditional devices that use sound waves (20Hz-20kHz), our technology generates pure electromagnetic frequencies that directly interact with your body's natural electromagnetic field.
            </p>
            
            <div className="grid grid-cols-2 gap-4 text-sm">
              <div className="bg-black/30 p-3 rounded-lg">
                <h4 className="text-purple-400 font-medium mb-2">Traditional Sound Devices</h4>
                <ul className="space-y-2 text-gray-400">
                  <li>• Require speakers/headphones</li>
                  <li>• Limited by audible range</li>
                  <li>• Can disturb others</li>
                </ul>
              </div>
              <div className="bg-black/30 p-3 rounded-lg">
                <h4 className="text-purple-400 font-medium mb-2">Our Technology</h4>
                <ul className="space-y-2 text-gray-400">
                  <li>• Silent operation</li>
                  <li>• Precise frequency control</li>
                  <li>• Direct cellular interaction</li>
                </ul>
              </div>
            </div>

            <div className="text-sm text-gray-400 bg-purple-500/10 p-3 rounded-lg">
              <strong className="text-purple-300">How it works: </strong>
              Our device generates specific electromagnetic frequencies that match Earth's natural resonances, allowing your body to synchronize with these beneficial frequencies without the need for sound waves.
            </div>
            {/* New Video Demonstration Section */}
<div className="mt-6 space-y-4">
  <h4 className="text-purple-400 font-medium">EMF Detection Proof</h4>
  <div className="relative rounded-xl overflow-hidden bg-black/40 border border-purple-500/20">
    <video
      className="w-full rounded-xl"
      autoPlay
      loop
      muted
      playsInline
    >
      <source src="/video waves.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    
    {/* Video Caption */}
    <div className="p-4 bg-black/60 backdrop-blur-sm">
      <p className="text-sm text-gray-300">
        <span className="text-purple-400 font-medium">Scientific Verification: </span>
        Watch as an industrial EMF detection device measures the precise frequency output of our device, demonstrating the real electromagnetic frequencies being generated.
      </p>
    </div>
  </div>
</div>
          </div>
        </div>
      </motion.div>
    )}
  </AnimatePresence>
</div>
                <div className="space-y-6">
                  {packages.map((pkg) => (
                    <motion.div
                      key={pkg.id}
                      className={`backdrop-blur-sm rounded-xl overflow-hidden
                        ${pkg.id === 4 
                          ? 'bg-white text-black border-0'
                          : 'bg-white/5 border border-purple-500/20'
                        }`}
                    >
                      <button
                        onClick={() => setExpandedPackage(expandedPackage === pkg.id ? null : pkg.id)}
                        className={`w-full text-left p-6 
                          ${pkg.id === 4 
                            ? 'text-black hover:bg-white' 
                            : 'text-white hover:bg-white/10'
                          }`}
                      >
                        <div className="flex justify-between items-center">
                          <div>
                            <h3 className={`text-2xl font-bold 
                              ${pkg.id === 4
                                ? 'bg-gradient-to-r from-[#FFD700] to-[#B8860B] bg-clip-text text-transparent'
                                : 'bg-gradient-to-r from-purple-400 to-pink-600 bg-clip-text text-transparent'
                              }`}>
                              {pkg.name}
                            </h3>
                            <p className={`${pkg.id === 4 ? 'text-gray-700' : 'text-gray-400'}`}>
                              {pkg.subtitle}
                            </p>
                          </div>
                          <div className="text-right">
                            <div className={`text-2xl font-bold ${pkg.id === 4 
                              ? 'bg-gradient-to-r from-[#FFD700] to-[#B8860B] bg-clip-text text-transparent'
                              : 'text-white'}`}>
                              ${pkg.price}
                            </div>
                            <div className={`text-sm ${pkg.id === 4 ? 'text-gray-500' : 'text-gray-400'} line-through`}>
                              ${pkg.originalPrice.toFixed(2)}
                            </div>
                            <div className={`text-sm ${pkg.id === 4 ? 'text-[#FFD700]' : 'text-green-400'}`}>
                              Save ${pkg.savings.toFixed(2)}!
                            </div>
                          </div>
                        </div>
                      </button>

                      <motion.div
                        initial={false}
                        animate={{ height: expandedPackage === pkg.id ? 'auto' : 0 }}
                        className="overflow-hidden"
                      >
                        <div className={`p-6 pt-0 ${pkg.id === 4 ? 'bg-white' : ''}`}>
                          {pkg.id === 1 && (
                            <div className="bg-black text-white overflow-hidden border border-purple-500/20">
                              {/* Hero Section */}
                              <div className="relative h-[400px] sm:h-[500px] overflow-hidden bg-black/20 rounded-xl">
                                <div className="absolute inset-0" style={{
                                  clipPath: "polygon(0% 20px, 20px 0%, calc(100% - 20px) 0%, 100% 20px, 100% 100%, 0% 100%)"
                                }}>
                                  <img 
                                    src="/crn.png" 
                                    alt="The Original Schumann 7.83 Device" 
                                    className="w-full h-full object-contain p-4 transform hover:scale-105 transition-transform duration-700"
                                  />
                                </div>
                                <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent rounded-xl" />
                                <div className="absolute bottom-4 left-4 right-4 bg-black/60 backdrop-blur-sm rounded-xl p-6 text-white border border-purple-500/20">
                                  <div className="flex flex-col space-y-2">
                                    <div className="flex items-center justify-between">
                                      
                                    </div>
                                   
                                    
                                  </div>
                                </div>
                              </div>

                              <div id="product-options" className="px-4 sm:px-8 -mt-20 relative z-10">
                                <div className="bg-black/60 backdrop-blur-sm rounded-xl p-6 shadow-lg border border-purple-500/20">
                                  {/* Main Content */}
                                  <h3 className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent mb-4">
                                    The Original Fixed 7.83Hz Device
                                  </h3>
                                  
                                  <p className="text-gray-300 text-sm sm:text-base leading-relaxed mb-6">
                                    Our flagship model, trusted by over 10,000 customers worldwide. Experience the pure, unaltered Earth frequency that humans evolved with for millions of years.
                                  </p>

                                  {/* Key Features */}
                                  <div className="grid grid-cols-2 sm:grid-cols-4 gap-3 mb-6">
                                    <div className="text-center p-3 bg-black/40 rounded-xl border border-purple-500/10">
                                      <span className="text-lg sm:text-xl">⚡</span>
                                      <p className="text-xs sm:text-sm mt-1 text-gray-300">Fixed 7.83Hz</p>
                                    </div>
                                    <div className="text-center p-3 bg-black/40 rounded-xl border border-purple-500/10">
                                      <span className="text-lg sm:text-xl">⚡</span>
                                      <p className="text-xs sm:text-sm mt-1 text-gray-300">190ft² Coverage</p>
                                    </div>
                                    <div className="text-center p-3 bg-black/40 rounded-xl border border-purple-500/10">
                                      <span className="text-lg sm:text-xl">✧</span>
                                      <p className="text-xs sm:text-sm mt-1 text-gray-300">EMF Protection</p>
                                    </div>
                                    <div className="text-center p-3 bg-black/40 rounded-xl border border-purple-500/10">
                                      <span className="text-lg sm:text-xl">🔋</span>
                                      <p className="text-xs sm:text-sm mt-1 text-gray-300">Rechargeable Battery</p>
                                    </div>
                                  </div>

                                  {/* Benefits Grid - Modified to prevent mobile cropping */}
                                  <div className="grid sm:grid-cols-2 gap-6 mb-6">
                                    <img 
                                      src="/Designer(21).jpg" 
                                      alt="Schumann Device Benefits" 
                                      className="w-full h-auto sm:h-[300px] object-contain sm:object-cover rounded-xl"
                                    />
                                    <div className="space-y-4 bg-black/40 p-4 rounded-xl border border-purple-500/10">
                                      <h4 className="text-lg font-semibold bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
                                        Why Choose The Original 7.83Hz?
                                      </h4>
                                      <div className="grid gap-3 text-sm">
                                        <div className="flex items-center space-x-2 text-gray-300">
                                          <span>🌟</span>
                                          <span>Pure, unaltered Earth frequency</span>
                                        </div>
                                        <div className="flex items-center space-x-2 text-gray-300">
                                          <span>💫</span>
                                          <span>Our best-selling model since 2019</span>
                                        </div>
                                        <div className="flex items-center space-x-2 text-gray-300">
                                          <span>🧘</span>
                                          <span>Perfect for beginners & practitioners</span>
                                        </div>
                                        <div className="flex items-center space-x-2 text-gray-300">
                                          <span>⚡</span>
                                          <span>Consistent, reliable performance</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Added CTA Button */}
                                  <div className="mt-6 space-y-4">
                                    <div className="bg-purple-900/20 p-4 rounded-xl border border-purple-500/20">
                                      <p className="text-center font-semibold text-purple-200 text-sm">
                                        🚚 Free Express Shipping | 🛡️ 1-Year Warranty
                                      </p>
                                      <p className="text-center text-red-400 font-bold text-sm animate-pulse">
                                     Limited Promotion Discount Price
                                      </p>
                                    </div>

                                    <button
                                      onClick={() => {
                                        handleAddToCart(pkg);
                                        setIsCartOpen(true);
                                      }}
                                      className="w-full bg-gradient-to-r from-purple-600 to-purple-800
                                                        text-white py-4 px-6 rounded-lg font-semibold 
                                                        hover:from-purple-700 hover:to-purple-900 
                                                        transition-all duration-300 transform hover:scale-[1.02]
                                                        shadow-lg flex items-center justify-center space-x-2"
                                    >
                                      <span>Optimize Your Life Today - Save ${31.00.toFixed(2)}</span>
                                      <span className="text-xl">→</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {pkg.id === 2 && (
                            <div className="bg-black/40 backdrop-blur-sm rounded-xl text-white overflow-hidden border border-purple-500/20">
                                <div className="relative h-[300px] overflow-hidden">
                                    <img 
                                        src="/blog/lifestyle/frequency-meditation.jpg" 
                                        alt="Frequency Meditation" 
                                        className="w-full h-full object-cover p-4 scale-110"
                                    />
                                    <div className="absolute bottom-0 inset-x-0 bg-gradient-to-t from-black to-transparent h-24"/>
                                </div>

                                <div className="p-6">
                                    <h3 className="text-xl font-bold bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent mb-4">
                                      24/7 Protection at Work & Home
                                    </h3>
                                    
                                    <p className="text-gray-300 mb-6">
                                      Stay in harmony with Earth's frequency wherever you are. Keep one device at home and one at work, or protect multiple rooms in your house for complete coverage.
                                    </p>

                                    <div className="grid grid-cols-2 gap-4 mb-6">
                                      <div className="bg-black/40 p-4 rounded-xl border border-purple-500/10">
                                        <h4 className="font-semibold mb-2">Perfect For:</h4>
                                        <ul className="space-y-2 text-sm text-gray-300">
                                          <li className="flex items-center gap-2">
                                            <HomeIcon className="h-4 w-4 text-purple-400" />
                                            Home & Office Setup
                                          </li>
                                          <li className="flex items-center gap-2">
                                            <BeakerIcon className="h-4 w-4 text-purple-400" />
                                            Multiple Rooms
                                          </li>
                                          <li className="flex items-center gap-2">
                                            <UserGroupIcon className="h-4 w-4 text-purple-400" />
                                            Couples & Partners
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="bg-black/40 p-4 rounded-xl border border-purple-500/10">
                                        <h4 className="font-semibold mb-2">Added Benefits:</h4>
                                        <ul className="space-y-2 text-sm text-gray-300">
                                          <li className="flex items-center gap-2">
                                            <SparklesIcon className="h-4 w-4 text-purple-400" />
                                            Extended Coverage
                                          </li>
                                          <li className="flex items-center gap-2">
                                            <BookOpenIcon className="h-4 w-4 text-purple-400" />
                                            Free EMF Guide
                                          </li>
                                          <li className="flex items-center gap-2">
                                            <GiftIcon className="h-4 w-4 text-purple-400" />
                                            Save $110.01
                                          </li>
                                        </ul>
                                      </div>
                                    </div>

                                    <div className="space-y-4">
                                      <div className="bg-purple-900/20 p-4 rounded-xl border border-purple-500/20">
                                        <p className="text-center font-semibold text-purple-200 text-sm">
                                           Free Express Shipping | 🛡️ 1-Year Warranty
                                        </p>
                                        <p className="text-center text-red-400 font-bold text-sm animate-pulse">
                                        Limited Promotion Discount Price
                                        </p>
                                      </div>

                                      <button
                                        onClick={() => {
                                          handleAddToCart(pkg);
                                          setIsCartOpen(true);
                                        }}
                                        className="w-full bg-gradient-to-r from-purple-600 to-purple-800
                                                          text-white py-4 px-6 rounded-lg font-semibold 
                                                          hover:from-purple-700 hover:to-purple-900 
                                                          transition-all duration-300 transform hover:scale-[1.02]
                                                          shadow-lg flex items-center justify-center space-x-2"
                                      >
                                        <span>Get Complete Coverage - Save ${100.01.toFixed(2)}</span>
                                        <span className="text-xl">→</span>
                                      </button>
                                    </div>
                                </div>
                            </div>
                          )}
                          {pkg.id === 3 && (
                            <div className="bg-black/40 backdrop-blur-sm rounded-xl text-white overflow-hidden border border-purple-500/20">
                                <div className="relative h-[300px] overflow-hidden">
                                    <img 
                                        src="/blog/technology/u9416112711_Smart_Home_Frequency_Optimization_How_to_create_a_1e0d0ef2-4c25-430b-9aae-ed58b4461681_1.png" 
                                        alt="Smart Home Frequency Optimization" 
                                        className="w-full h-full object-cover p-4 scale-110"
                                    />
                                    <div className="absolute bottom-0 inset-x-0 bg-gradient-to-t from-black to-transparent h-24"/>
                                </div>

                                <div className="p-6">
                                    <h3 className="text-xl font-bold bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent mb-4">
                                      24/7 Protection for Your Entire Home
                                    </h3>
                                    
                                    <p className="text-gray-300 mb-6">
                                      Protect your entire home with three Schumann 7.83 devices. This is the ultimate solution for complete EMF protection and stress relief.
                                    </p>

                                    <div className="grid grid-cols-2 gap-4 mb-6">
                                      <div className="bg-black/40 p-4 rounded-xl border border-purple-500/10">
                                        <h4 className="font-semibold mb-2">Perfect For:</h4>
                                        <ul className="space-y-2 text-sm text-gray-300">
                                          <li className="flex items-center gap-2">
                                            <HomeIcon className="h-4 w-4 text-purple-400" />
                                            Entire Home Setup
                                          </li>
                                          <li className="flex items-center gap-2">
                                            <BeakerIcon className="h-4 w-4 text-purple-400" />
                                            Multiple Rooms
                                          </li>
                                          <li className="flex items-center gap-2">
                                            <UserGroupIcon className="h-4 w-4 text-purple-400" />
                                            Families & Couples
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="bg-black/40 p-4 rounded-xl border border-purple-500/10">
                                        <h4 className="font-semibold mb-2">Added Benefits:</h4>
                                        <ul className="space-y-2 text-sm text-gray-300">
                                          <li className="flex items-center gap-2">
                                            <SparklesIcon className="h-4 w-4 text-purple-400" />
                                            Whole-Home Coverage
                                          </li>
                                          <li className="flex items-center gap-2">
                                            <BookOpenIcon className="h-4 w-4 text-purple-400" />
                                            Free EMF Guide
                                          </li>
                                          <li className="flex items-center gap-2">
                                            <GiftIcon className="h-4 w-4 text-purple-400" />
                                            Save $190.01
                                          </li>
                                        </ul>
                                      </div>
                                    </div>

                                    <div className="space-y-4">
                                      <div className="bg-purple-900/20 p-4 rounded-xl border border-purple-500/20">
                                        <p className="text-center font-semibold text-purple-200 text-sm">
                                          🚚 Free Express Shipping | 🛡️ 1-Year Warranty
                                        </p>
                                        <p className="text-center text-red-400 font-bold text-sm animate-pulse">
                                        Limited Promotion Discount Price
                                        </p>
                                      </div>

                                      <button
                                        onClick={() => {
                                          handleAddToCart(pkg);
                                          setIsCartOpen(true);
                                        }}
                                        className="w-full bg-gradient-to-r from-purple-600 to-purple-800
                                                          text-white py-4 px-6 rounded-lg font-semibold 
                                                          hover:from-purple-700 hover:to-purple-900 
                                                          transition-all duration-300 transform hover:scale-[1.02]
                                                          shadow-lg flex items-center justify-center space-x-2"
                                      >
                                        <span>Get Complete Protection - Save ${170.01.toFixed(2)}</span>
                                        <span className="text-xl">→</span>
                                      </button>
                                    </div>
                                </div>
                            </div>
                          )}
                          {pkg.id === 4 && (
                            <div className="bg-white rounded-xl text-black overflow-hidden border-0">
                              {/* Hero Section with Main Product Image */}
                              <div className="relative h-[400px] sm:h-[500px] overflow-hidden bg-white">
                                <img 
                                  src="/por4.png" 
                                  alt="Schumann Pro Device with Digital Display" 
                                  className="w-full h-full object-contain p-4 transform hover:scale-105 transition-transform duration-700"
                                />
                                <div className="absolute inset-0 bg-gradient-to-t from-white via-transparent to-transparent" />
                                <div className="absolute bottom-4 left-4 right-4 bg-white backdrop-blur-sm rounded-xl p-4 text-black border border-gray-100">
                                  <div className="flex items-center justify-between">
                                   
                                    <div className="text-right">
                                      <p className="text-xs sm:text-sm font-medium bg-purple-500/20 px-3 py-1 rounded-full">
                                        Premium Model
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div id="product-options" className="px-4 sm:px-8 -mt-20 relative z-10">
                                <div className="bg-white rounded-xl p-6 shadow-lg border border-purple-100">
                                  {/* Main Content */}
                                  <h3 className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-[#FFD700] to-[#B8860B] bg-clip-text text-transparent mb-4">
                                    Why Choose Schumann Pro?
                                  </h3>
                                  
                                  <p className="text-gray-800 text-sm sm:text-base leading-relaxed mb-6">
                                    Building on our original Schumann device, the Pro version represents the pinnacle of frequency therapy technology. With its advanced digital display and precise frequency control, it's a quantum leap in personal wellness technology.
                                  </p>

                                  {/* Features Grid - Simplified */}
                                  <div className="grid grid-cols-2 sm:grid-cols-4 gap-3 mb-6">
                                    <div className="text-center">
                                      <span className="text-lg sm:text-xl">🔬</span>
                                      <p className="text-xs sm:text-sm mt-1">Full Spectrum Control 0.1-999.99Hz</p>
                                    </div>
                                    <div className="text-center">
                                      <span className="text-lg sm:text-xl">📱</span>
                                      <p className="text-xs sm:text-sm mt-1">Digital Interface for Frequency Control</p>
                                    </div>
                                    <div className="text-center">
                                      <span className="text-lg sm:text-xl">⚡</span>
                                      <p className="text-xs sm:text-sm mt-1">Enhanced Output 2x times the EMF protection</p>
                                    </div>
                                    <div className="text-center">
                                      <span className="text-lg sm:text-xl">🔋</span>
                                      <p className="text-xs sm:text-sm mt-1">Rechargeable Battery</p>
                                    </div>
                                  </div>

                                  {/* Second Image with Frequency Benefits */}
                                  <div className="relative mb-6 rounded-xl overflow-hidden">
                                    <div className="grid sm:grid-cols-2 gap-6">
                                      
                                                                                    {/* Waveform Animation Container */}
                                                                                    <div className="relative">
                                              

                                                {/* Content with glass effect */}
                                                <div className="relative z-10 bg-black/60 backdrop-blur-sm p-4 rounded-lg border border-purple-500/20 mb-4">
                                                  {/* Waveform Control Header */}
                                                  <div className="flex items-center justify-between mb-2">
                                                    <span className="text-sm text-cyan-300">Waveform Control:</span>
                                                    <motion.div
                                                      animate={{ opacity: [1, 0.5, 1] }}
                                                      transition={{ duration: 2, repeat: Infinity }}
                                                      className="text-xs text-cyan-400"
                                                    >
                                                      Active
                                                    </motion.div>
                                                  </div>
                                                  <div className="relative h-16 bg-black/60 rounded-md overflow-hidden">
                                                    {/* Sine Wave */}
                                                    <motion.div
                                                      className="absolute inset-0 flex items-center justify-center"
                                                      animate={{ opacity: [1, 0, 1] }}
                                                      transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
                                                    >
                                                      <svg width="100%" height="40" viewBox="0 0 200 40">
                                                        <motion.path
                                                          d="M 0 20 Q 25 0, 50 20 T 100 20 T 150 20 T 200 20"
                                                          fill="none"
                                                          stroke="url(#sineGradient)"
                                                          strokeWidth="2"
                                                          animate={{
                                                            d: [
                                                              "M 0 20 Q 25 0, 50 20 T 100 20 T 150 20 T 200 20",
                                                              "M -50 20 Q -25 0, 0 20 T 50 20 T 100 20 T 150 20",
                                                              "M -100 20 Q -75 0, -50 20 T 0 20 T 50 20 T 100 20"
                                                            ]
                                                          }}
                                                          transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                                                        />
                                                        <defs>
                                                          <linearGradient id="sineGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                                            <stop offset="0%" stopColor="#9333EA" />
                                                            <stop offset="100%" stopColor="#EC4899" />
                                                          </linearGradient>
                                                        </defs>
                                                      </svg>
                                                    </motion.div>
                                                    {/* Square Wave */}
                                                    <motion.div
                                                      className="absolute inset-0 flex items-center justify-center"
                                                      animate={{ opacity: [0, 1, 0] }}
                                                      transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
                                                    >
                                                      <svg width="100%" height="40" viewBox="0 0 200 40">
                                                        <motion.path
                                                          d="M 0 30 L 0 10 L 50 10 L 50 30 L 100 30 L 100 10 L 150 10 L 150 30 L 200 30"
                                                          fill="none"
                                                          stroke="url(#squareGradient)"
                                                          strokeWidth="2"
                                                          animate={{
                                                            strokeDashoffset: [0, -200]
                                                          }}
                                                          strokeDasharray="200"
                                                          transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                                                        />
                                                        <defs>
                                                          <linearGradient id="squareGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                                            <stop offset="0%" stopColor="#FFD700" />
                                                            <stop offset="100%" stopColor="#B8860B" />
                                                          </linearGradient>
                                                        </defs>
                                                      </svg>
                                                    </motion.div>
                                                  </div>
                                                  {/* Wave Type Labels */}
                                                  <div className="flex justify-between mt-2 items-center">
                                                    <span className="text-sm font-semibold bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
                                                      Sine Wave
                                                    </span>
                                                    <motion.span
                                                      animate={{ opacity: [1, 0.5, 1] }}
                                                      transition={{ duration: 4, repeat: Infinity }}
                                                      className="text-cyan-400 mx-2"
                                                    >
                                                      ↔
                                                    </motion.span>
                                                    <span className="text-sm font-semibold bg-gradient-to-r from-[#FFD700] to-[#B8860B] text-transparent bg-clip-text">
                                                      Square Wave
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="space-y-4">
  {/* Pro Background Image Section */}
<div className="relative w-full h-[300px] rounded-xl overflow-hidden mb-4">
  <img 
    src="/BACKPRO.webp"
    alt="Professional Waveform Technology"
    className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-700"
  />
  <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent" />
  <div className="absolute bottom-4 left-4 right-4 p-4 bg-black/60 backdrop-blur-sm rounded-lg border border-purple-500/20">
    <h4 className="text-lg font-semibold bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
      Advanced Waveform Technology
    </h4>
    <p className="text-sm text-gray-300 mt-1">
      Professional-grade frequency generation with precise waveform control
    </p>
  </div>
</div>
 
<div className="mt-2 mb-4 flex justify-center">
  <Link to="/user-guide" className="inline-block bg-gradient-to-r from-purple-500 to-blue-600 hover:from-purple-600 hover:to-blue-700 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
   Full User Guide
  </Link>
</div>
                                       {/* Enhanced Link to User Guide for Frequencies */}
                                       <div className="mt-2 mb-4 flex justify-center">
  <Link to="/frequencies" className="inline-block bg-gradient-to-r from-purple-500 to-blue-600 hover:from-purple-600 hover:to-blue-700 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
    Explore All Frequencies
  </Link>
</div>
          
                                      </div>
                                    </div>
                                  </div>

                                  {/* Professional Use Cases - Simplified */}
                                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 mb-6">
                                    <div className="text-center">
                                      <span className="text-2xl">👨‍⚕️</span>
                                      <h5 className="font-semibold text-sm mt-2">Healthcare</h5>
                                      <p className="text-xs text-gray-600">Precise frequency control for clinical applications</p>
                                    </div>
                                    <div className="text-center">
                                      <span className="text-2xl">🧘‍♀️</span>
                                      <h5 className="font-semibold text-sm mt-2">Wellness</h5>
                                      <p className="text-xs text-gray-600">Customizable programs for different practices</p>
                                    </div>
                                    <div className="text-center">
                                      <span className="text-2xl">🏃</span>
                                      <h5 className="font-semibold text-sm mt-2">Sports</h5>
                                      <p className="text-xs text-gray-600">Adjustable frequencies for peak performance</p>
                                    </div>
                                  </div>

                                  {/* CTA Section */}
                                  <div className="space-y-4">
                                    <div className="bg-amber-50 p-4 rounded-xl border border-amber-100">
                                      <p className="text-center font-semibold text-amber-800 text-sm">
                                        🚚 Free Express Shipping | 🛡️ 1-Year Warranty
                                      </p>
                                      <p className="text-center text-red-600 font-bold text-sm animate-pulse">
                                        Limited Stock Available
                                      </p>
                                    </div>

                                    <button
                                      onClick={() => handleAddToCart(pkg)}
                                      className="w-full bg-gradient-to-r from-purple-600 to-purple-800
                                                       text-white py-4 px-6 rounded-lg font-semibold 
                                                       hover:from-purple-700 hover:to-purple-900 
                                                       transition-all duration-300 transform hover:scale-[1.02]
                                                       shadow-lg flex items-center justify-center space-x-2"
                                    >
                                      <span>Add to Cart - Save ${pkg.savings.toFixed(2)}</span>
                                      <span className="text-xl"></span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </motion.div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
              <div className="space-y-2 text-sm">
              <div className="mt-8">
  <button
    className="w-full py-4 px-6 bg-purple-600 text-white font-semibold rounded-lg shadow-lg"
    onClick={() => setDeviceGuidanceExpanded(!deviceGuidanceExpanded)}
  >
    Device Guidance
  </button>
  <AnimatePresence>
    {deviceGuidanceExpanded && (
      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={variants}
        transition={{ duration: 0.8, ease: "easeInOut" }}
        className="text-left bg-white text-black p-6 rounded-lg shadow-lg mt-4"
      >
        <h4 className="text-lg font-semibold mb-4">Deepening Your Connection with the Device</h4>
        <p>
          Integrating the Schumann device into your daily wellness routine can enhance your overall well-being. Here's how you can get the most out of your device:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>Start Slow:</strong> Begin with sessions during times of relaxation or meditation to enhance the calming effects.</li>
          <li><strong>Explore and Experiment:</strong> Use the device at different times and settings to see how the frequencies best support your lifestyle, whether it's boosting concentration during work or unwinding in the evening.</li>
        </ul>
        <p>
          During your initial uses, you might notice sensations such as:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>Tingling:</strong> A sign that the frequencies are interacting with your body's cellular structure, aiding in healing and regeneration.</li>
          <li><strong>Subtle Buzz:</strong> Indicates the device's frequencies are actively working to adjust and improve your body's natural rhythms.</li>
        </ul>
        <p>
          These sensations are normal and show that your body is responding to the device. They often occur in areas of previous discomfort or injury, signaling healing and recovery.
        </p>
        <p className="font-bold">
          Remember, frequency therapy is a personal journey. Patience and persistence are essential as you find the perfect balance for your needs. Trust the process and let the natural frequencies guide your path to wellness.
        </p>
        <div className="mt-2 mb-4 flex justify-center">
  <Link to="/user-guide" className="inline-block bg-gradient-to-r from-purple-500 to-blue-600 hover:from-purple-600 hover:to-blue-700 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
    Explore the Complete User Guide for V1 & V2
  </Link>
</div>
      </motion.div>
    )}
  </AnimatePresence>
</div>
{/* Shipping, Returns & Warranty Information Section */}

<div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
  <motion.div
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    viewport={{ once: true }}
    className="border border-white/10 rounded-xl overflow-hidden bg-gradient-to-r from-purple-900/20 to-pink-900/20"
  >
    <button
      onClick={() => setExpandedSection(expandedSection === 'shipping' ? null : 'shipping')}
      className="w-full py-4 px-6 flex justify-between items-center hover:bg-white/5 transition-colors"
    >
      <div className="flex items-center gap-3">
        <TruckIcon className="h-6 w-6 text-purple-400" />
        <h3 className="text-lg font-semibold text-white">Shipping, Returns & Warranty</h3>
      </div>
      <ChevronDownIcon
        className={`h-5 w-5 text-purple-400 transform transition-transform duration-200 ${
          expandedSection === 'shipping' ? 'rotate-180' : ''
        }`}
      />
    </button>

    <AnimatePresence>
      {expandedSection === 'shipping' && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="overflow-hidden"
        >
          <div className="p-6 space-y-6">
            {/* Shipping Information */}
            <div className="bg-black/40 rounded-xl p-5 border border-purple-500/20">
              <h4 className="text-purple-400 font-semibold mb-4 flex items-center gap-2">
                <TruckIcon className="h-5 w-5" />
                Shipping Details
              </h4>
              <ul className="space-y-3 text-gray-300">
                <li className="flex items-start gap-3">
                  <span className="text-purple-400 text-lg">•</span>
                  <span>Free Express Shipping on all orders worldwide</span>
                </li>
                <li className="flex items-start gap-3">
                  <span className="text-purple-400 text-lg">•</span>
                  <span>Delivery Time: 7-10 business days (tracking number provided)</span>
                </li>
                <li className="flex items-start gap-3">
                  <span className="text-purple-400 text-lg">•</span>
                  <span>Discreet, protective packaging for safe delivery</span>
                </li>
                <li className="flex items-start gap-3">
                  <span className="text-purple-400 text-lg">•</span>
                  <span>Real-time tracking updates via email</span>
                </li>
              </ul>
            </div>

            {/* Warranty Information - New Section */}
            <div className="bg-black/40 rounded-xl p-5 border border-purple-500/20">
              <h4 className="text-purple-400 font-semibold mb-4 flex items-center gap-2">
                <ShieldCheckIcon className="h-5 w-5" />
                1-Year Warranty
              </h4>
              <ul className="space-y-3 text-gray-300">
                <li className="flex items-start gap-3">
                  <span className="text-purple-400 text-lg">•</span>
                  <span>Full 1-year manufacturer's warranty included</span>
                </li>
                <li className="flex items-start gap-3">
                  <span className="text-purple-400 text-lg">•</span>
                  <span>Covers all manufacturing defects and malfunctions</span>
                </li>
                <li className="flex items-start gap-3">
                  <span className="text-purple-400 text-lg">•</span>
                  <span>Free replacement or repair during warranty period</span>
                </li>
                <li className="flex items-start gap-3">
                  <span className="text-purple-400 text-lg">•</span>
                  <span>Premium support throughout warranty duration</span>
                </li>
              </ul>
            </div>

            {/* Returns Information */}
            <div className="bg-black/40 rounded-xl p-5 border border-purple-500/20">
              <h4 className="text-purple-400 font-semibold mb-4 flex items-center gap-2">
                <ArrowRightIcon className="h-5 w-5 rotate-180" />
                30-Day Money Back Guarantee
              </h4>
              <ul className="space-y-3 text-gray-300">
                <li className="flex items-start gap-3">
                  <span className="text-purple-400 text-lg">•</span>
                  <span>Try The Schumann risk-free for 30 days</span>
                </li>
                <li className="flex items-start gap-3">
                  <span className="text-purple-400 text-lg">•</span>
                  <span>Simple, hassle-free return process</span>
                </li>
                <li className="flex items-start gap-3">
                  <span className="text-purple-400 text-lg">•</span>
                  <span>Full refund for undamaged items in original packaging</span>
                </li>
                <li className="flex items-start gap-3">
                  <span className="text-purple-400 text-lg">•</span>
                  <span>Contact support@theschumann.com for returns</span>
                </li>
              </ul>
            </div>

            {/* Trust Badges */}
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 pt-2">
              <div className="flex items-center justify-center gap-2 bg-black/40 p-3 rounded-lg border border-purple-500/20">
                <ShieldCheckIcon className="h-5 w-5 text-green-500" />
                <span className="text-sm text-gray-300">Secure Checkout</span>
              </div>
              <div className="flex items-center justify-center gap-2 bg-black/40 p-3 rounded-lg border border-purple-500/20">
                <TruckIcon className="h-5 w-5 text-blue-500" />
                <span className="text-sm text-gray-300">Free Shipping</span>
              </div>
              <div className="flex items-center justify-center gap-2 bg-black/40 p-3 rounded-lg border border-purple-500/20">
                <LockClosedIcon className="h-5 w-5 text-yellow-500" />
                <span className="text-sm text-gray-300">Money-Back Guarantee</span>
              </div>
              <div className="flex items-center justify-center gap-2 bg-black/40 p-3 rounded-lg border border-purple-500/20">
                <SparklesIcon className="h-5 w-5 text-purple-500" />
                <span className="text-sm text-gray-300">Premium Support</span>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  </motion.div>
</div>
  {/* Add new explanation section */}
  
  <div className="mt-4 bg-black/40 p-4 rounded-xl border border-blue-500/20">
    <h6 className="text-blue-400 font-semibold mb-2 flex items-center gap-2">
      <InformationCircleIcon className="h-5 w-5" />
      Why Phones Can't Replicate These Frequencies
    </h6>
    <p className="text-gray-300 text-sm leading-relaxed">
      Think of it like magnets vs. pictures of magnets. Just as a photo of a magnet has no magnetic field, 
      a phone playing a 7.83 Hz sound file doesn't generate the actual electromagnetic frequency. Our device 
      creates real electromagnetic fields, like a physical magnet, that your body can actually interact with 
      at the cellular level.
    </p>
    <div className="mt-3 grid grid-cols-2 gap-3 text-xs">
      <div className="bg-black/30 p-2 rounded-lg border border-red-500/20">
        <span className="text-red-400 font-semibold">Phone Limitations:</span>
        <ul className="mt-1 space-y-1 text-gray-400">
          <li>• Only produces audio waves</li>
          <li>• No electromagnetic field</li>
          <li>• Can't affect cellular activity</li>
        </ul>
      </div>
      <div className="bg-black/30 p-2 rounded-lg border border-green-500/20">
        <span className="text-green-400 font-semibold">Our Technology:</span>
        <ul className="mt-1 space-y-1 text-gray-400">
          <li>• True electromagnetic fields</li>
          <li>• Cellular-level interaction</li>
          <li>• Verified by EMF meters</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div className="mt-8">
 {/* Scientific Reality Section - Enhanced */}
 
<div className="mt-8">
  {/* Always visible content */}
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
   
  >
    {/* Hero Image Grid - Enhanced with Hover Effects */}
    <div className="grid md:grid-cols-2 gap-6 mb-8">
      <div className="relative group overflow-hidden rounded-xl cursor-pointer">
        <img 
          src="/blog/science/nasa-research.jpg"
          alt="NASA Research"
          className="w-full h-[300px] object-cover transform transition-transform duration-700 group-hover:scale-110"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent 
                        transition-opacity duration-300 group-hover:opacity-90" />
        <div className="absolute bottom-4 left-4 transform transition-transform duration-300 group-hover:translate-y-[-10px]">
          <h4 className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
            NASA-Validated Technology
          </h4>
          <p className="text-sm text-gray-200 mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            Discover how space research revolutionized wellness technology 
          </p>
        </div>
      </div>
      
      <div className="relative group overflow-hidden rounded-xl cursor-pointer">
        <img 
          src="/blog/science/schumann-discovery.jpg"
          alt="Schumann Discovery"
          className="w-full h-[300px] object-cover transform transition-transform duration-700 group-hover:scale-110"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent 
                        transition-opacity duration-300 group-hover:opacity-90" />
        <div className="absolute bottom-4 left-4 transform transition-transform duration-300 group-hover:translate-y-[-10px]">
          <h4 className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
            The Schumann Discovery
          </h4>
          <p className="text-sm text-gray-200 mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            Learn about Earth's natural healing frequency →
          </p>
        </div>
      </div>
    </div>

    {/* Enhanced Quote Section */}
    <div className="relative">
      <div className="absolute -left-2 top-0 text-4xl text-blue-500/20">❝</div>
      <div className="text-lg text-gray-300 italic pl-8 py-4 mb-6 border-l-4 border-blue-500/50">
        <p className="mb-2">
          "When astronauts first went into space, they experienced severe health issues. NASA's groundbreaking discovery? 
          The absence of Earth's natural 7.83 Hz frequency was the culprit."
        </p>
        <p className="text-sm text-blue-400 font-semibold not-italic">
          - NASA Space Research, 1960s
        </p>
      </div>
      <div className="absolute -right-2 bottom-0 text-4xl text-blue-500/20 transform rotate-180">❝</div>
    </div>

    {/* Enhanced Learn More Button */}
    <button
      onClick={() => setIsScientificExpanded(!isScientificExpanded)}
      className="w-full bg-gradient-to-r from-blue-600 to-purple-600 
                hover:from-blue-700 hover:to-purple-700
                text-white py-4 px-6 rounded-lg
                flex items-center justify-center gap-3
                transition-all duration-300 transform hover:scale-[1.02]
                group relative overflow-hidden"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-blue-400/20 to-purple-400/20 
                      transform transition-transform duration-500 
                      group-hover:scale-x-[200%] group-hover:scale-y-[2.5]" />
      <span className="font-semibold relative z-10 flex items-center gap-2">
        {isScientificExpanded ? (
          <>
            <span>Show Less</span>
            <ChevronUpIcon className="w-5 h-5 animate-bounce" />
          </>
        ) : (
          <>
            <span>Discover the Science Behind Our Technology</span>
            <ChevronDownIcon className="w-5 h-5 animate-pulse" />
          </>
        )}
      </span>
      <motion.div
        animate={{
          rotate: isScientificExpanded ? 720 : 0,
        }}
        transition={{ duration: 0.6, ease: "anticipate" }}
       
      >
       
      </motion.div>
    </button>
  </motion.div>

  <AnimatePresence mode="wait">
    {isScientificExpanded && (
      // ... existing AnimatePresence wrapper ...
      <motion.div className="p-6 border border-purple-500/20">
        {/* New Research Timeline Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ 
            opacity: 1, 
            y: 0,
            transition: { delay: 0.3, duration: 0.4 }
          }}
          className="mb-8"
        >
          <div className="relative">
            <div className="absolute left-4 top-0 bottom-0 w-0.5 bg-blue-500/20" />
            
            {[
              {
                year: "1952",
                title: "The Discovery",
                content: "Prof. Schumann discovers Earth's resonant frequency of 7.83 Hz",
                icon: "🔬"
              },
              {
                year: "1960s",
                title: "NASA Crisis",
                content: "Astronauts face health issues in space due to frequency absence",
                icon: "🚀"
              },
              {
                year: "1970s",
                title: "Breakthrough",
                content: "Development of frequency generators for space missions",
                icon: "⚡"
              },
              {
                year: "Present",
                title: "Modern Application",
                content: "Advanced technology brings space benefits to everyday life",
                icon: "🌍"
              }
            ].map((item, index) => (
              <div key={index} className="ml-8 mb-6 relative">
                <div className="absolute -left-10 top-0 w-6 h-6 bg-blue-500/20 rounded-full 
                                flex items-center justify-center border border-blue-500">
                  <div className="text-sm">{item.icon}</div>
                </div>
                <div className="bg-blue-900/20 rounded-xl p-4 border border-blue-500/20">
                  <div className="text-blue-400 font-bold">{item.year}</div>
                  <div className="text-lg font-semibold text-white mb-1">{item.title}</div>
                  <div className="text-gray-300">{item.content}</div>
                </div>
              </div>
            ))}
          </div>
        </motion.div>


{/* Scientific Background Section */}
<motion.div 
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.4 }}
  className="space-y-8 mt-12"
>
  {/* Schumann Discovery */}
  <div className="bg-zinc-800/30 p-8 rounded-2xl border border-white/10">
    <h3 className="text-xl font-bold text-blue-400 mb-4">The Schumann Phenomenon</h3>
    <p className="text-gray-300 mb-6">
      While teaching his students about the physics of electricity, Professor Schumann hypothesized that the Earth's surface and ionosphere created a resonant cavity. This cavity, he proposed, could sustain electromagnetic waves at specific frequencies. Through mathematical calculations, he predicted a fundamental frequency of approximately 7.83 Hz, now known as the primary Schumann Resonance.
    </p>
    <div className="grid md:grid-cols-2 gap-6">
      <div className="space-y-3">
        <h4 className="font-semibold text-purple-400">Primary Frequencies</h4>
        <ul className="space-y-2 text-gray-300">
          <li className="flex items-center gap-2">
            <span className="text-purple-400">◆</span>
            7.83 Hz - Primary Resonance
          </li>
          <li className="flex items-center gap-2">
            <span className="text-purple-400">◆</span>
            14.3 Hz - Second Harmonic
          </li>
          <li className="flex items-center gap-2">
            <span className="text-purple-400">◆</span>
            20.8 Hz - Third Harmonic
          </li>
        </ul>
      </div>
      <div className="space-y-3">
        <h4 className="font-semibold text-purple-400">Biological Impact</h4>
        <ul className="space-y-2 text-gray-300">
          <li className="flex items-center gap-2">
            <CheckCircleIcon className="h-5 w-5 text-green-500" />
            Matches human alpha brain waves
          </li>
          <li className="flex items-center gap-2">
            <CheckCircleIcon className="h-5 w-5 text-green-500" />
            Synchronizes biological rhythms
          </li>
          <li className="flex items-center gap-2">
            <CheckCircleIcon className="h-5 w-5 text-green-500" />
            Enhances cellular regeneration
          </li>
        </ul>
      </div>
    </div>
  </div>

  {/* NASA Connection */}
  <div className="bg-zinc-800/30 p-8 rounded-2xl border border-white/10">
    <div className="flex items-start gap-6">
      <div className="flex-1">
        <h3 className="text-xl font-bold text-blue-400 mb-4">NASA's Breakthrough</h3>
        <p className="text-gray-300 mb-6">
          Originally developed to protect astronauts in space, where the absence of Earth's natural frequency led to significant health deterioration. NASA's research revealed that maintaining this vital 7.83 Hz frequency was crucial for astronaut well-being.
        </p>
        <ul className="space-y-4">
          <li className="flex items-start">
            <SparklesIcon className="h-6 w-6 text-purple-500 mr-2 flex-shrink-0 mt-1" />
            <span className="text-gray-300">Maintaining astronaut cognitive function</span>
          </li>
          <li className="flex items-start">
            <SparklesIcon className="h-6 w-6 text-purple-500 mr-2 flex-shrink-0 mt-1" />
            <span className="text-gray-300">Preserving sleep-wake cycles in space</span>
          </li>
          <li className="flex items-start">
            <SparklesIcon className="h-6 w-6 text-purple-500 mr-2 flex-shrink-0 mt-1" />
            <span className="text-gray-300">Supporting overall crew well-being</span>
          </li>
        </ul>
      </div>
      <div className="hidden md:block w-1/3">
        <img 
          src="/nasa-logo.png" 
          alt="NASA" 
          className="w-full opacity-50"
        />
      </div>
    </div>
  </div>

  {/* Modern Medical Applications */}
  <div className="mt-8 bg-zinc-800/30 p-8 rounded-2xl border border-white/10">
    <div className="grid md:grid-cols-2 gap-8">
      <div>
        <h3 className="text-xl font-bold text-pink-400 mb-4">Modern Medical Applications</h3>
        <p className="text-gray-300 mb-6">
          Today's medical community is increasingly recognizing frequency-based therapies as a complementary approach to traditional treatments. Our research collaborations with leading institutions have demonstrated remarkable results:
        </p>
        <ul className="space-y-4">
          <li className="flex items-start">
            <BeakerIcon className="h-6 w-6 text-pink-500 mr-2 flex-shrink-0 mt-1" />
            <div>
              <span className="text-gray-200 font-semibold">Clinical Studies:</span>
              <span className="text-gray-300"> 94% of participants reported improved sleep quality within 14 days</span>
            </div>
          </li>
          <li className="flex items-start">
            <ChartBarIcon className="h-6 w-6 text-pink-500 mr-2 flex-shrink-0 mt-1" />
            <div>
              <span className="text-gray-200 font-semibold">Stress Response:</span>
              <span className="text-gray-300"> 47% reduction in cortisol levels after 30 days of use</span>
            </div>
          </li>
          <li className="flex items-start">
            <HeartIcon className="h-6 w-6 text-pink-500 mr-2 flex-shrink-0 mt-1" />
            <div>
              <span className="text-gray-200 font-semibold">Recovery:</span>
              <span className="text-gray-300"> 68% faster physical recovery rates in athletic performance tests</span>
            </div>
          </li>
        </ul>
      </div>
      <div className="space-y-6">
        <div className="bg-black/30 p-6 rounded-xl border border-pink-500/20">
          <h4 className="text-lg font-semibold text-pink-400 mb-3">Research Partnerships</h4>
          <div className="grid grid-cols-2 gap-4">
            <div className="text-center p-4 bg-zinc-900/50 rounded-lg">
              <div className="text-2xl font-bold text-pink-400">12+</div>
              <div className="text-sm text-gray-400">Research Institutions</div>
            </div>
            <div className="text-center p-4 bg-zinc-900/50 rounded-lg">
              <div className="text-2xl font-bold text-pink-400">5000+</div>
              <div className="text-sm text-gray-400">Study Participants</div>
            </div>
            <div className="text-center p-4 bg-zinc-900/50 rounded-lg">
              <div className="text-2xl font-bold text-pink-400">3</div>
              <div className="text-sm text-gray-400">Clinical Trials</div>
            </div>
            <div className="text-center p-4 bg-zinc-900/50 rounded-lg">
              <div className="text-2xl font-bold text-pink-400">94%</div>
              <div className="text-sm text-gray-400">Success Rate</div>
            </div>
          </div>
        </div>
        <div className="relative h-[200px] rounded-xl overflow-hidden">
          <img 
            src="/blog/research/clinical-trials.jpg" 
            alt="Clinical Research" 
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent flex items-end p-4">
            <p className="text-sm text-gray-300">
              Ongoing clinical trials at our partner research facilities
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  {/* After the Modern Medical Applications section */}
  {/* EMF Protection Technology */}
  <div className="mt-8 bg-zinc-800/30 p-8 rounded-2xl border border-white/10">
    <div className="grid md:grid-cols-2 gap-8">
      <div>
        <h3 className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent mb-4">
          Advanced EMF Protection Technology
        </h3>
        <p className="text-gray-300 mb-6">
          In today's wireless world, we're constantly bombarded by electromagnetic frequencies (EMF) from devices. Our advanced shielding technology provides a protective barrier while maintaining the beneficial 7.83 Hz frequency.
        </p>
        
        <div className="space-y-6">
          <div className="bg-black/30 p-4 rounded-lg border border-blue-500/20">
            <h4 className="text-blue-400 font-semibold mb-2">Multi-Layer Shield Technology</h4>
            <ul className="space-y-3">
              <li className="flex items-start gap-2">
                <ShieldCheckIcon className="h-5 w-5 text-blue-400 mt-1 flex-shrink-0" />
                <span className="text-gray-300">-40dB EMF reduction across all frequencies</span>
              </li>
              <li className="flex items-start gap-2">
                <ShieldCheckIcon className="h-5 w-5 text-blue-400 mt-1 flex-shrink-0" />
                <span className="text-gray-300">Blocks up to 98% of harmful radiation</span>
              </li>
              <li className="flex items-start gap-2">
                <ShieldCheckIcon className="h-5 w-5 text-blue-400 mt-1 flex-shrink-0" />
                <span className="text-gray-300">Aerospace-grade shielding materials</span>
              </li>
            </ul>
          </div>

          <div className="bg-black/30 p-4 rounded-lg border border-purple-500/20">
            <h4 className="text-purple-400 font-semibold mb-2">Protection From Modern Devices</h4>
            <div className="grid grid-cols-2 gap-3">
              <div className="flex items-center gap-2">
                <DevicePhoneMobileIcon className="h-5 w-5 text-purple-400" />
                <span className="text-gray-300 text-sm">Mobile Phones</span>
              </div>
              <div className="flex items-center gap-2">
                <ComputerDesktopIcon className="h-5 w-5 text-purple-400" />
                <span className="text-gray-300 text-sm">Computers</span>
              </div>
              <div className="flex items-center gap-2">
                <WifiIcon className="h-5 w-5 text-purple-400" />
                <span className="text-gray-300 text-sm">WiFi Routers</span>
              </div>
              <div className="flex items-center gap-2">
                <SignalIcon className="h-5 w-5 text-purple-400" />
                <span className="text-gray-300 text-sm">5G Networks</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-6">
        <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 p-6 rounded-xl border border-blue-500/20">
          <h4 className="text-lg font-semibold text-blue-400 mb-4">EMF Protection Metrics</h4>
          <div className="space-y-4">
            {/* EMF Reduction Bar */}
            <div>
              <div className="flex justify-between text-sm mb-1">
                <span className="text-gray-300">EMF Reduction</span>
                <span className="text-blue-400">98%</span>
              </div>
              <div className="h-2 bg-gray-700 rounded-full">
                <div className="h-full w-[98%] bg-gradient-to-r from-blue-400 to-purple-400 rounded-full" />
              </div>
            </div>
            
            {/* Coverage Area Bar */}
            <div>
              <div className="flex justify-between text-sm mb-1">
                <span className="text-gray-300">Coverage Area</span>
                <span className="text-blue-400">190ft²</span>
              </div>
              <div className="h-2 bg-gray-700 rounded-full">
                <div className="h-full w-[85%] bg-gradient-to-r from-blue-400 to-purple-400 rounded-full" />
              </div>
            </div>
            
            {/* Frequency Accuracy Bar */}
            <div>
              <div className="flex justify-between text-sm mb-1">
                <span className="text-gray-300">Frequency Accuracy</span>
                <span className="text-blue-400">99.99%</span>
              </div>
              <div className="h-2 bg-gray-700 rounded-full">
                <div className="h-full w-[99.99%] bg-gradient-to-r from-blue-400 to-purple-400 rounded-full" />
              </div>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-xl" />
          <div className="bg-black/60 backdrop-blur-sm p-6 rounded-xl border border-blue-500/20 relative">
            <h4 className="text-lg font-semibold text-blue-400 mb-3">Why EMF Protection Matters</h4>
            <ul className="space-y-3">
              <li className="flex items-start gap-2">
                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-500 mt-1 flex-shrink-0" />
                <span className="text-gray-300 text-sm">Average person exposed to 100,000x more EMF than 20 years ago</span>
              </li>
              <li className="flex items-start gap-2">
                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-500 mt-1 flex-shrink-0" />
                <span className="text-gray-300 text-sm">5G networks increase EMF exposure by up to 200%</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</motion.div>

{/* CTA Section */}
<motion.div
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.7 }}
  className="mt-8 bg-gradient-to-r from-blue-900/40 to-purple-900/40 rounded-xl p-6 border border-blue-500/20"
>
  <div className="text-center space-y-4">
    <h4 className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
      Optimize Your Life Today
    </h4>
    <p className="text-gray-300 max-w-2xl mx-auto">
      Choose between our classic V1 or advanced V2 Pro models to begin your wellness journey.
    </p>
    <div className="flex justify-center">
      <button
        onClick={() => {
          const element = document.getElementById('product-options');
          if (element) {
            const yOffset = -20;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({
              top: y,
              behavior: 'smooth'
            });
          }
        }}
        className="mt-6 bg-gradient-to-r from-blue-600 to-purple-600 
                  hover:from-blue-700 hover:to-purple-700
                  text-white py-4 px-8 rounded-lg font-semibold
                  transition-all duration-300 transform hover:scale-[1.02]
                  shadow-lg flex items-center justify-center gap-3
                  group mx-auto"
      >
        <span>View Product Options</span>
        <motion.div
          animate={{
            rotate: 720,
          }}
          transition={{ duration: 0.6, ease: "anticipate" }}
          className="relative w-4 h-4 sm:w-6 sm:h-6 rounded-full overflow-hidden border border-purple-500/20"
        >
          <img 
            src="/favicon-32x32.png" 
            alt="Schumann Logo" 
            className="w-full h-full object-cover"
          />
        </motion.div>
      </button>
    </div>
  </div>
</motion.div>
      </motion.div>
    )}
  </AnimatePresence>
</div>

  {/* For the Key Differences button */}
  <div className="science-section"></div>
 
  <button
    onClick={() => setIsComparisonExpanded(!isComparisonExpanded)}
    className="w-full bg-black/40 backdrop-blur-sm rounded-xl px-6 py-4 
              border border-purple-500/20 flex justify-between items-center 
              hover:bg-purple-900/20 transition-colors duration-200"
  >
    <div className="text-left">
      <h4 className="text-xl font-bold flex items-center gap-1">
        <span className="bg-gradient-to-r from-purple-400 to-pink-600 bg-clip-text text-transparent">
          Key Differences & Frequencies: V1 vs 
          </span>
        <span className="bg-gradient-to-r from-[#FFD700] to-[#B8860B] bg-clip-text text-transparent">
        V2 Pro
        </span>
      </h4>
      <span className="text-sm text-purple-400/60 block mt-1">Compare models & explore frequencies →</span>
      </div>
    <motion.div
      animate={{
        rotate: isComparisonExpanded ? 720 : 0,
      }}
      transition={{ duration: 0.6, ease: "anticipate" }}
    
    >
     
    </motion.div>
  </button>

  <AnimatePresence mode="wait">
    {isComparisonExpanded && (
        <motion.div
        initial={{ opacity: 0, height: 0, y: -20 }}
        animate={{ 
          opacity: 1, 
          height: "auto", 
          y: 0,
          transition: {
            height: {
              duration: 0.4,
              ease: "easeOut"
            },
            opacity: {
              duration: 0.3,
              delay: 0.1
            }
          }
        }}
        exit={{ 
          opacity: 0,
          height: 0,
          y: -20,
          transition: {
            height: {
              duration: 0.3
            },
            opacity: {
              duration: 0.2
            }
          }
        }}
        className="mt-4 bg-black/40 backdrop-blur-sm rounded-xl overflow-hidden"
      >
            <motion.div
          initial={{ opacity: 0 }}
          animate={{ 
            opacity: 1,
            transition: { delay: 0.2, duration: 0.3 }
          }}
          className="p-6 border border-purple-500/20"
        >
          {/* Basic Comparison Grid */}
                <motion.div
            className="grid gap-6 md:grid-cols-3 mb-8"
            initial={{ opacity: 0, y: 20 }}
                  animate={{
              opacity: 1, 
              y: 0,
              transition: { delay: 0.3, duration: 0.4 }
            }}
          >
{/* Enhanced Link to User Guide */}
<div className="mt-2 mb-4 flex justify-center">
  <Link to="/user-guide" className="inline-block bg-gradient-to-r from-purple-500 to-blue-600 hover:from-purple-600 hover:to-blue-700 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
    Explore the Complete User Guide for V1 & V2
  </Link>
</div>
            {/* Frequency Control */}
            <div className="space-y-3">
              <h5 className="text-purple-400 font-semibold">Frequency Control</h5>
              <div className="space-y-2 text-sm">
                <div className="flex flex-col gap-1">
                  <span className="text-gray-400">V1 Standard:</span>
                  <span className="text-white">Fixed 7.83Hz</span>
                </div>
                <div className="flex flex-col gap-1">
                  <span className="text-gray-400">V2 Pro:</span>
                  <span className="text-white">Full range (0.1-999.99Hz)</span>
                  <span className="text-blue-400">Digital display & controls</span>
                  <span className="text-blue-400">〰️ Waveform switching (sine/square)</span>
                </div>
              </div>
              </div>

            {/* Coverage & Features */}
            <div className="space-y-3">
              <h5 className="text-purple-400 font-semibold">Coverage & Features</h5>
              <div className="space-y-2 text-sm">
                <div className="flex flex-col gap-1">
                  <span className="text-gray-400">V1 Standard:</span>
                  <span className="text-white">190ft²</span>
                </div>
                <div className="flex flex-col gap-1">
                  <span className="text-gray-400">V2 Pro:</span>
                  <span className="text-white">250ft²</span>
                
                </div>
              </div>
            </div>

            {/* Battery Life */}
            <div className="space-y-3">
              <h5 className="text-purple-400 font-semibold"> Rechargeable Battery Life</h5>
              
           

              <div className="space-y-2 text-sm">
                
                <p className="text-blue-400 text-xs italic mt-2">
                  Both versions include AC adapters for continuous operation. Extended battery life enables flexible mobility for travel and outdoor use.
                </p>
              </div>
            </div>
          </motion.div>

        

     
            </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
</div>
<div className="mt-8 mb-8">
  <div className="bg-black/40 backdrop-blur-sm rounded-xl p-6 border border-purple-500/20 relative overflow-hidden">
    {/* Animated Background Elements */}
    <div className="absolute inset-0 overflow-hidden">
      {/* Resonance Wave Animation */}
      <div className="absolute inset-0 opacity-20">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[200%] h-[200%]">
          {[...Array(3)].map((_, i) => (
            <div
              key={i}
              className="absolute inset-0 border-2 border-purple-500/30 rounded-full animate-ripple"
              style={{
                animationDelay: `${i * 1}s`,
                width: '100%',
                height: '100%'
              }}
            />
          ))}
        </div>
          </div>

      {/* Floating Particles */}
      <div className="absolute inset-0">
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className="absolute w-1 h-1 bg-purple-400/40 rounded-full animate-float"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 5}s`,
              animationDuration: `${5 + Math.random() * 5}s`
            }}
          />
        ))}
      </div>
      
      {/* Glowing Orb */}
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-64 h-64">
        <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 to-pink-500/20 rounded-full blur-3xl animate-pulse" />
      </div>
    </div>

    {/* Content */}
    <div className="relative z-10">
      <h4 className="text-2xl font-bold text-center bg-gradient-to-r from-purple-400 to-pink-600 bg-clip-text text-transparent mb-4">
        Experience Nature's Frequency At Every Stage of Life
      </h4>
      
      <p className="text-gray-300 text-center mb-6 max-w-2xl mx-auto text-sm">
        The 7.83 Hz Schumann frequency isn't just for one type of person - it's nature's gift to everyone.
      </p>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
        {[
          {
            icon: "👶",
            title: "Children",
            benefits: [
              "Protection from EMF",
              "Better focus",
              "Healthy sleep"
            ]
          },
          {
            icon: "💼",
            title: "Work",
            benefits: [
              "34% more productive",
              "Less stress",
              "EMF shield"
            ]
          },
          {
            icon: "😴",
            title: "Sleep",
            benefits: [
              "82% deeper sleep",
              "Stress reduction",
              "Better well-being"
            ]
          },
          {
            icon: "👴",
            title: "Seniors",
            benefits: [
              "Brain health",
              "Cell regeneration",
              "Quality sleep"
            ]
          }
        ].map((category, index) => (
          <div 
            key={index}
            className="group relative bg-purple-900/20 backdrop-blur-sm rounded-lg p-4 border border-purple-500/20
                       hover:bg-purple-800/30 transition-all duration-300 transform hover:scale-[1.02]"
          >
            {/* Hover Effect */}
            <div className="absolute inset-0 bg-gradient-to-r from-purple-600/0 to-pink-600/0 opacity-0 
                          group-hover:opacity-20 rounded-lg transition-opacity duration-300" />
            
            <div className="text-xl mb-2 transform group-hover:scale-110 transition-transform duration-300">
              {category.icon}
            </div>
            <h5 className="text-sm font-semibold text-purple-300 mb-2">{category.title}</h5>
            <ul className="space-y-1">
              {category.benefits.map((benefit, i) => (
                <li key={i} className="flex items-center gap-1 text-xs text-gray-300">
                  <span className="text-purple-400 text-[10px]">◆</span>
                  <span>{benefit}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  </div>
</div>

{/* Add these keyframes to your global CSS */}
<style jsx global>{`
  @keyframes ripple {
    0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
    }
  }

  @keyframes float {
    0%, 100% {
      transform: translateY(0) translateX(0);
    }
    50% {
      transform: translateY(-20px) translateX(10px);
    }
  }

  @keyframes float-around {
    0% {
      transform: translate(-50%, -50%) rotate(0deg) translateX(10px) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg) translateX(10px) rotate(-360deg);
    }
  }

  .animate-ripple {
    animation: ripple 4s linear infinite;
  }

  .animate-float {
    animation: float 5s ease-in-out infinite;
  }
`}</style>

<div className="mt-8 space-y-4">
  {/* Technical Specifications Section */}
  <div className="science-section"></div>
  <motion.div 
    className="mt-8"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, ease: "easeOut" }}
  >
    <button
      onClick={() => setIsTechSpecsExpanded(!isTechSpecsExpanded)}
      className="w-full bg-black/40 backdrop-blur-sm rounded-xl px-6 py-4 
                border border-purple-500/20 flex justify-between items-center 
                hover:bg-purple-900/20 transition-colors duration-200"
    >
      <div className="text-left">
        <h4 className="text-xl font-bold text-purple-400">
          Technical Specifications
        </h4>
        <span className="text-sm text-purple-400/60 block mt-1">View full specs →</span>
      </div>
      <motion.div
        animate={{
          rotate: isTechSpecsExpanded ? 720 : 0,
        }}
        transition={{ duration: 0.6, ease: "anticipate" }}
        className="relative w-5 h-5 sm:w-8 sm:h-8 rounded-full overflow-hidden border border-purple-500/20"
      >
        <img 
          src="/favicon-32x32.png" 
          alt="Schumann Logo" 
          className="w-full h-full object-cover"
        />
      </motion.div>
    </button>

    <AnimatePresence mode="wait">
      {isTechSpecsExpanded && (
        <motion.div
          initial={{ opacity: 0, height: 0, y: -20 }}
          animate={{ 
            opacity: 1, 
            height: "auto", 
            y: 0,
            transition: {
              height: {
                duration: 0.4,
                ease: "easeOut"
              },
              opacity: {
                duration: 0.3,
                delay: 0.1
              }
            }
          }}
          exit={{ 
            opacity: 0,
            height: 0,
            y: -20,
            transition: {
              height: {
                duration: 0.3
              },
              opacity: {
                duration: 0.2
              }
            }
          }}
          className="mt-4 bg-black/40 backdrop-blur-sm rounded-xl overflow-hidden"
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ 
              opacity: 1,
              transition: { delay: 0.2, duration: 0.3 }
            }}
            className="p-6 border border-purple-500/20"
          >
            {/* Core Features */}
            <div>
              <h5 className="text-lg font-semibold text-purple-400 mb-4">Core Features</h5>
              <ul className="space-y-3 text-gray-300">
                <li className="flex items-start gap-2">
                  <CheckCircleIcon className="w-5 h-5 text-purple-400 mt-0.5 flex-shrink-0" />
                  <span>Frequency Range: 7.83Hz ± 0.5Hz - 0.5 to 999.99Hz for Pro (very low frequency electromagnetic wave with no sound)</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircleIcon className="w-5 h-5 text-purple-400 mt-0.5 flex-shrink-0" />
                  <span>Coverage Area: Up to 190ft²</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircleIcon className="w-5 h-5 text-purple-400 mt-0.5 flex-shrink-0" />
                  <span>Power Output: 0.5W - 2W</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircleIcon className="w-5 h-5 text-purple-400 mt-0.5 flex-shrink-0" />
                  <span>Battery: 2000mAh Li-ion</span>
                </li>
              </ul>
            </div>

            {/* Connectivity */}
            <div>
              <h5 className="text-lg font-semibold text-purple-400 mb-4">Connectivity</h5>
              <ul className="space-y-3 text-gray-300">
                <li className="flex items-start gap-2">
                  <CheckCircleIcon className="w-5 h-5 text-purple-400 mt-0.5 flex-shrink-0" />
                  <span>USB-C Charging Port</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircleIcon className="w-5 h-5 text-purple-400 mt-0.5 flex-shrink-0" />
                  <span>LED Status Indicators</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircleIcon className="w-5 h-5 text-purple-400 mt-0.5 flex-shrink-0" />
                  <span>Touch Controls</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircleIcon className="w-5 h-5 text-purple-400 mt-0.5 flex-shrink-0" />
                  <span>Auto Sleep Mode</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircleIcon className="w-5 h-5 text-purple-400 mt-0.5 flex-shrink-0" />
                  <span>EMF Shield: -40dB</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircleIcon className="w-5 h-5 text-purple-400 mt-0.5 flex-shrink-0" />
                  <span>Runtime: 12+ hours per charge</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircleIcon className="w-5 h-5 text-purple-400 mt-0.5 flex-shrink-0" />
                  <span>Rechargeable via USB-C</span>
                </li>
              </ul>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  </motion.div>

 
</div>

{/* Scientific Validation & Social Proof Section */}

<div className="w-full py-16 space-y-16">
  {/* Featured In Section */}
  <motion.div 
    className="w-full py-12 bg-gradient-to-r from-purple-900/40 to-black/40 backdrop-blur-sm border-t border-b border-purple-500/10"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: 0.8 }}
  >
    <div className="max-w-7xl mx-auto px-4">
      <h3 className="text-center text-gray-400 text-sm mb-8 uppercase tracking-wider">Featured In</h3>
      
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 items-center justify-items-center">
        {[
          { src: nytLogo, alt: "New York Times" },
          { src: forbesLogo, alt: "Forbes" },
          { src: nasaLogo, alt: "NASA" },
          { src: scientificAmericanLogo, alt: "Scientific American" }
        ].map((logo, index) => (
          <motion.img
            key={index}
            src={logo.src}
            alt={logo.alt}
            className="h-4 md:h-6 w-auto opacity-70 hover:opacity-100 transition-opacity filter brightness-0 invert"
            whileHover={{ scale: 1.05 }}
          />
        ))}
      </div>
    </div>
  </motion.div>

 


</div>
              {/* FAQ Section */}
              
              <div className="mt-12">
                <button
                  onClick={() => setIsFaqSectionExpanded(!isFaqSectionExpanded)}
                  className="w-full max-w-3xl mx-auto mb-6 bg-black/40 backdrop-blur-sm border border-purple-500/20 rounded-lg px-6 py-4 
                            flex justify-between items-center hover:bg-purple-900/20 transition-colors duration-200"
                >
                  <div className="text-left">
                    <h3 className="text-2xl font-bold text-white">Frequently Asked Questions</h3>
                    <span className="text-sm text-purple-400/60 block mt-1">Find answers to common questions →</span>
                  </div>
                  <motion.div
                    animate={{
                      rotate: isFaqSectionExpanded ? 720 : 0,
                    }}
                    transition={{ duration: 0.6, ease: "anticipate" }}
                    className="relative w-5 h-5 sm:w-8 sm:h-8 rounded-full overflow-hidden border border-purple-500/20"
                  >
                    <img 
                      src="/favicon-32x32.png" 
                      alt="Schumann Logo" 
                      className="w-full h-full object-cover"
                    />
                  </motion.div>
                </button>

                <AnimatePresence mode="wait">
                  {isFaqSectionExpanded && (
                    <motion.div
                      initial={{ opacity: 0, height: 0, y: -20 }}
                      animate={{ 
                        opacity: 1, 
                        height: "auto", 
                        y: 0,
                        transition: {
                          height: {
                            duration: 0.4,
                            ease: "easeOut"
                          },
                          opacity: {
                            duration: 0.3,
                            delay: 0.1
                          }
                        }
                      }}
                      exit={{ 
                        opacity: 0,
                        height: 0,
                        y: -20,
                        transition: {
                          height: {
                            duration: 0.3
                          },
                          opacity: {
                            duration: 0.2
                          }
                        }
                      }}
                      className="max-w-3xl mx-auto bg-black/40 backdrop-blur-sm rounded-xl overflow-hidden"
                    >
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ 
                          opacity: 1,
                          transition: { delay: 0.2, duration: 0.3 }
                        }}
                        className="space-y-4"
                      >
                        {/* Your existing FAQ content */}
                        {[
                          {
                            question: "Can I leave it plugged in all the time?",
                            answer: "Yes! It's built for continuous operation without overheating, ensuring that you experience the calming benefits 24/7, like improved sleep and reduced stress."
                          },
                          {
                            question: "Is it safe for pets and children?",
                            answer: "Absolutely! The natural 7.83 Hz frequency is completely safe for pets and children. Many parents and pet owners report improvements in calmness, focus, and sleep."
                          },
                          {
                            question: "Do I need a speaker?",
                            answer: "No, the Schumann emits a low frequency wave you don't hear. You will know it works properly by the blue status light emitting."
                          },
                          {
                            question: "How does it improve sleep?",
                            answer: "The 7.83 Hz frequency promotes deeper sleep by reducing cortisol levels, allowing for more restorative rest and improved overall sleep quality."
                          },
                          {
                            question: "Can I use The Schumann without interfering with my daily devices?",
                            answer: "Yes! The Schumann resonance device works alongside other electronics, providing EMF protection without interfering with your daily devices. It helps shield you from harmful EMF signals while supporting your overall well-being."
                          },
                          {
                            question: "Did NASA use this technology?",
                            answer: "Yes, based on NASA-validated technology! Originally developed to protect astronauts from health deterioration in space, this frequency has been shown to: Maintain cognitive function, preserve sleep-wake cycles and support overall crew well-being."
                          },
                          {
                            question: "Is there a warranty for this device?",
                            answer: "Yes! Our device comes with a 1-year warranty, ensuring that you're covered and confident in your purchase. If there's an issue, we've got you protected."
                          },
                          {
                            question: "How do I get the most out of my Schumann Resonance device?",
                            answer: "For optimal results, place it near your bed or workspace and keep it plugged in for continuous, long-term benefits. Consistency is key to unlocking its full potential."
                          },
                          {
                            question: "Why is your version so cheap? Everyone else's is over $1,000.00.",
                            answer: `Thank you for asking - We take that as a huge compliment!

                            1. Mission-Driven Approach
                            • Our primary goal is to make Earth's healing frequency accessible to everyone
                            • We believe wellness shouldn't be a luxury only few can afford
                            • The more people connected to 7.83 Hz, the better our collective wellbeing

                            2. Advanced Manufacturing
                            • We've invested heavily in state-of-the-art production facilities
                            • Mass production allows us to achieve economies of scale
                            • Direct-to-consumer model eliminates middleman markups

                            3. Long-Term Vision
                            • We prioritize volume over margins
                            • Building a movement rather than maximizing profit
                            • The more devices we get out there, the more data we collect to improve

                            4. Cutting-Edge Technology
                            • Our innovative design requires fewer components
                            • Highly efficient production process
                            • Premium quality without unnecessary frills

                            Remember: Our competitors' higher prices don't necessarily mean better quality. We've simply chosen to make this technology accessible while maintaining the highest standards of performance and durability.

                            The real question is: Why should anyone have to pay $1000+ to reconnect with Earth's natural frequency? 🌍`
                          }
                        ].map((faq, index) => (
                          <div 
                            key={index}
                            className="border border-purple-500/20 rounded-lg overflow-hidden bg-black/40 backdrop-blur-sm"
                          >
                            <button
                              onClick={() => setExpandedFaq(expandedFaq === index ? null : index)}
                              className="w-full px-6 py-4 text-left flex justify-between items-center hover:bg-purple-900/20 transition-colors duration-200"
                            >
                              <span className="font-semibold text-white">{faq.question}</span>
                              <ChevronDownIcon 
                                className={`w-5 h-5 text-purple-400 transform transition-transform duration-200 ${
                                  expandedFaq === index ? 'rotate-180' : ''
                                }`}
                              />
                            </button>
                            
                            {expandedFaq === index && (
                              <div className="px-6 py-4 text-gray-300 whitespace-pre-line">
                                {faq.answer}
                              </div>
                            )}
                          </div>
                        ))}
                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              {/* Reviews Section */}
           
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8 }}
                className="mt-16"
              >
                {/* New Gradient Heading */}
                
                <motion.h1 
                  className="text-3xl sm:text-5xl md:text-7xl font-bold mx-auto max-w-[95%] sm:max-w-none text-center mb-16"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-purple-200 to-white inline-block">
                    What Our Customers
                  </span>
                  <br />
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600 inline-block">
                    Say About Us
                  </span>
                </motion.h1>

                {/* Additional Reviews */}
                <div 
  id="reviews-section"  // Add this id
  className="columns-2 sm:columns-2 lg:columns-2 gap-2 sm:gap-4 lg:gap-12 mt-8 
            [&>*]:break-inside-avoid px-2 sm:px-4 lg:px-12 max-w-[1400px] mx-auto"
>
  {[
    {
      image: "/7c4ef1673c004f3c984efb712149e087.mov",  // Video first
      type: "video"
    },
    {
      image: "/IMG_6167.jpeg",  // Image second
      type: "image"
    },
    {
      image: "/REVSLIKE/REV2_11zon.webp",
    },
    {
      image: "/REVSLIKE/REV3_11zon.webp",
    },
    {
      image: "/REVSLIKE/REV4_11zon.webp",
    },
    {
      image: "/REVSLIKE/REV5_11zon.webp",
    },
    {
      image: "/REVSLIKE/REV6_11zon.webp",
    },
    {
      image: "/REVSLIKE/REV7_11zon.webp",
    },
    {
      image: "/REVSLIKE/REV8_11zon.webp",
    },
    {
      image: "/REVSLIKE/REV9_11zon.webp",
    },
    {
      image: "/REVSLIKE/REV10_11zon.webp",
    },
    {
      image: "/REVSLIKE/REV11_11zon.webp",
    },
    {
      image: "/REVSLIKE/REV12_11zon.webp",
    },
    {
      image: "/REVSLIKE/REV13_11zon.webp",
    },
    {
      image: "/REVSLIKE/REV14_11zon.webp",
    },
    {
      image: "/REVSLIKE/REV15_11zon-1.webp",
    },
    {
      image: "/REVSLIKE/REV16_11zon.webp",
                    }
                  ].map((review, index) => (
    <motion.div 
                      key={index} 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
      className="bg-black/30 rounded-lg p-1 sm:p-2 lg:p-3 border border-purple-500/20 
                 hover:border-purple-500/40 transition-all duration-300 mb-2 sm:mb-4 lg:mb-8
                 cursor-zoom-in shadow-lg hover:shadow-purple-500/20"
      onClick={() => setSelectedMedia(review.image)}
    >
      <div className="rounded-lg overflow-hidden">
        {review.type === "video" ? (
          <video 
            src={review.image}
            className="w-full object-contain lg:hover:scale-[1.03] transition-transform duration-300"
            playsInline
            muted
            loop
            autoPlay
          />
        ) : (
          <motion.img 
            src={review.image} 
            alt=""
            className="w-full object-contain lg:hover:scale-[1.03] transition-transform duration-300"
            whileHover={{ scale: 1.02 }}
            layoutId={`zoom-${review.image}`}
          />
        )}
      </div>
    </motion.div>
  ))}
                            </div>

              
                        </motion.div>
            </div>
          </div>
        </div>
      </div>
{/* Final CTA - Why Just 7.83Hz? */}
<motion.div
  initial={{ opacity: 0, y: 20 }}
  whileInView={{ opacity: 1, y: 0 }}
  viewport={{ once: true }}
  className="text-center max-w-4xl mx-auto px-4 py-16"
>
  <h2 className="text-2xl sm:text-3xl font-bold mb-3 sm:mb-4">Why 7.83Hz?</h2>
  <div className="text-sm sm:text-lg text-gray-300 mb-6 sm:mb-8 max-w-2xl mx-auto leading-relaxed space-y-4">
    <p>
      This isn't random - it's the exact frequency our bodies have evolved with over millions of years. Just like you wouldn't want to adjust the temperature of your body from its optimal 98.6°F (37°C), the 7.83 Hz frequency is precisely calibrated for maximum effectiveness ✨
    </p>
    <div className="bg-gradient-to-r from-purple-900/30 to-blue-900/30 p-6 rounded-xl border border-purple-500/20">
      <p className="font-semibold text-purple-400 mb-3">Our clinical studies and research demonstrate that this specific frequency:</p>
      <ul className="space-y-2 text-left">
        <li className="flex items-center gap-2">
          <span className="text-purple-400">🧠</span> Matches our brain's alpha/theta wave transition
        </li>
        <li className="flex items-center gap-2">
          <span className="text-purple-400">✨</span> Optimizes cellular regeneration cycles
        </li>
        <li className="flex items-center gap-2">
          <span className="text-purple-400">🛡️</span> Provides the most effective EMF protection
        </li>
        <li className="flex items-center gap-2">
          <span className="text-purple-400">😴</span> Delivers the best sleep enhancement results (82% of users experience deeper sleep)
        </li>
      </ul>
    </div>
    <p>
      We've invested heavily in perfecting the precision delivery of this specific frequency - think of it like a finely tuned musical instrument that's always perfectly in pitch! 🎯
    </p>
  </div>

  <div className="bg-gradient-to-r from-purple-900/40 to-pink-900/40 p-6 rounded-xl border border-purple-500/20 mb-8">
    <p className="text-lg font-semibold text-purple-400 mb-4">
      🔥 Limited Time Offer - Save 30% Today!
    </p>
    <p className="text-gray-300 mb-4">
      Join thousands who've already transformed their lives with the power of 7.83 Hz.
      Don't miss out on this exclusive discount!
    </p>
    <div className="flex justify-center items-center gap-3 mb-4">
      <span className="text-3xl font-bold text-white">$69.99</span>
      <span className="text-xl text-gray-400 line-through">$100.00</span>
    </div>
    <button
      onClick={() => {
        const optionsSection = document.getElementById('product-options');
        optionsSection?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }}
      className="bg-gradient-to-r from-purple-600 to-pink-600 text-white 
        px-8 py-4 rounded-full text-lg font-semibold 
        hover:from-purple-700 hover:to-pink-700 
        transition-all duration-200 
        shadow-lg hover:shadow-purple-500/20
        transform hover:scale-105"
    >
      Claim Your Device Now
    </button>
  </div>

  <div className="flex flex-col sm:flex-row gap-3 justify-center items-center">
    <div className="flex items-center">
      <ShieldCheckIcon className="h-5 w-5 sm:h-6 sm:w-6 text-green-500 mr-2" />
      <Link 
        to="/return-policy" 
        className="text-sm sm:text-base text-gray-300 hover:text-purple-400 transition-colors duration-200"
      >
        30-Day Money Back Guarantee
      </Link>
    </div>
    <div className="flex items-center">
      <TruckIcon className="h-5 w-5 sm:h-6 sm:w-6 text-blue-500 mr-2" />
      <Link 
        to="/return-policy" 
        className="text-sm sm:text-base text-gray-300 hover:text-purple-400 transition-colors duration-200"
      >
        Free Express Shipping
      </Link>
    </div>
  </div>
</motion.div>
 {/* Enhanced CTA Section */}
 <div className="text-center space-y-4 bg-gradient-to-r from-purple-50 to-pink-50 p-6 rounded-xl">
    <h4 className="text-xl font-semibold text-gray-800">Ready to Elevate Your Practice?</h4>
    <p className="text-gray-600">Join the professional community using the most advanced frequency therapy technology available</p>

    <button
      onClick={() => {
        const cartItem = {
          id: 4,
          name: "The Schumann Pro",
          price: 159.00,
          originalPrice: 200.00,
          quantity: 1,
          type: "pro"
        };
        addToCart(cartItem);
        setIsCartOpen(true);
      }}
      className="bg-gradient-to-r from-purple-600 to-pink-600 text-white 
        px-10 py-4 rounded-full text-lg font-semibold 
        hover:from-purple-700 hover:to-pink-700 
        transition-all duration-200 
        shadow-xl hover:shadow-purple-500/20
        transform hover:scale-105"
    >
      Upgrade to Schumann Pro
    </button>
    <p className="text-sm text-gray-500">Limited Availability - Professional Grade Equipment</p>
  </div>
  <motion.div
  initial={{ y: 100 }}
  animate={{ y: showScrollButton ? 0 : 100 }}
  transition={{ 
    duration: 0.3,
    type: "spring",
    stiffness: 260,
    damping: 20
  }}
  className="fixed bottom-0 left-0 right-0 bg-black/95 backdrop-blur-sm p-3 sm:p-4 z-50 border-t border-gray-800 shadow-lg"
>
  <div className="max-w-7xl mx-auto flex flex-col gap-4">
    {/* Quick Navigation Links */}
    <div className="flex items-center justify-center space-x-6">
      <button
        onClick={() => {
          const element = document.querySelector('.features-section');
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }}
        className="text-xs sm:text-sm text-gray-300 hover:text-purple-400 transition-colors duration-200 flex items-center gap-1"
      >
        <SparklesIcon className="h-3 w-3 sm:h-4 sm:w-4" />
        <span>Benefits</span>
      </button>
      <button
        onClick={() => {
          const element = document.querySelector('.columns-2.sm\\:columns-2');
          element?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }}
        className="text-xs sm:text-sm text-gray-300 hover:text-purple-400 transition-colors duration-200 flex items-center gap-1"
      >
        <StarIcon className="h-3 w-3 sm:h-4 sm:w-4" />
        <span>Reviews</span>
      </button>
      <button
        onClick={() => {
          const element = document.querySelector('#product-options');
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }}
        className="text-xs sm:text-sm text-gray-300 hover:text-purple-400 transition-colors duration-200 flex items-center gap-1"
      >
        <ShoppingCartIcon className="h-3 w-3 sm:h-4 sm:w-4" />
        <span>Order</span>
      </button>
      <button
        onClick={() => {
          const element = document.querySelector('.science-section');
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }}
        className="text-xs sm:text-sm text-gray-300 hover:text-purple-400 transition-colors duration-200 flex items-center gap-1"
      >
        <BeakerIcon className="h-3 w-3 sm:h-4 sm:w-4" />
        <span>Science</span>
      </button>
      <button
        onClick={() => navigate('/user-guide')}
        className="text-xs sm:text-sm text-gray-300 hover:text-purple-400 transition-colors duration-200 flex items-center gap-1"
      >
        <BookOpenIcon className="h-3 w-3 sm:h-4 sm:w-4" />
        <span>Guide</span>
      </button>
    </div>

    {/* Buy Buttons */}
    <div className="flex justify-center gap-3">
      <motion.button
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        onClick={() => {
          handleQuickBuy(1);
          setIsCartOpen(true);
        }}
        className="px-4 sm:px-6 py-2.5 sm:py-3 bg-gradient-to-r from-purple-600 to-black text-white 
          font-semibold rounded-lg hover:from-purple-700 hover:to-gray-900 
          transition-all duration-300 transform hover:scale-105
          flex items-center gap-2 whitespace-nowrap text-sm sm:text-base"
      >
        <span>Buy 7.83Hz V1</span>
      </motion.button>

      <motion.button
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.3 }}
        onClick={() => {
          handleQuickBuy(4);
          setIsCartOpen(true);
        }}
        className="px-4 sm:px-6 py-2.5 sm:py-3 bg-white font-semibold rounded-lg 
          hover:bg-gray-100 transition-all duration-300 transform hover:scale-105
          border border-gray-200 flex items-center gap-2 whitespace-nowrap text-sm sm:text-base"
      >
        <span className="bg-gradient-to-r from-[#FFD700] to-[#B8860B] bg-clip-text text-transparent">
          Go Pro V2
        </span>
      </motion.button>
    </div>
  </div>
</motion.div>
      {/* Modals and Sticky Button */}
      {selectedImage && (
        <ImageModal image={selectedImage} onClose={handleCloseZoom} />
      )}

      {showStickyButton && (
        <div className="fixed bottom-16 sm:bottom-0 left-0 right-0 bg-black/90 backdrop-blur-sm p-4 border-t border-purple-500/20">
          <div className="max-w-7xl mx-auto flex justify-between items-center">
            <div className="text-lg font-semibold">Ready to transform your life?</div>
            <button
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              className="bg-gradient-to-r from-purple-600 to-pink-600 text-white py-2 px-6 rounded-lg font-semibold hover:from-purple-700 hover:to-pink-700 transition-colors"
            >
              Choose Your Package
            </button>
          </div>
        </div>
      )}

      {showStickyNav && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="sticky top-0 bg-white shadow-md z-50 p-4"
        >
          <nav>
            <ul className="flex justify-around">
              <li><a href="#features">Features</a></li>
              <li><a href="#pricing">Pricing</a></li>
              <li><a href="#reviews">Reviews</a></li>
              <li><a href="#guidance">Device Guidance</a></li>
            </ul>
          </nav>
        </motion.div>
      )}

      {/* Sticky Nav */}
      <AnimatePresence>
        {showNav && (
          <StickyNav 
            passedAncestralSection={passedAncestralSection}
            setPassedAncestralSection={setPassedAncestralSection}
          />
        )}
      </AnimatePresence>
    </div>
  );
}



