import React from 'react';

function ReturnPolicy() {
  return (
    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 mt-20">
      <h1 className="text-3xl font-bold mb-8 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
        Return Policy
      </h1>
      
      <div className="prose prose-lg max-w-none">
        <h2 className="text-2xl font-semibold mb-4">Our Return Policy</h2>
        
        <p className="mb-4">
          We want you to be completely satisfied with your purchase. We offer a straightforward and fair return policy because we stand behind our product's quality and effectiveness.
        </p>

        <h3 className="text-xl font-semibold mb-3">30-Day Money-Back Guarantee</h3>
        <p className="mb-4">
          You have 30 days from the date of delivery to initiate a return. We believe this gives you ample time to experience the benefits of our technology.
        </p>

        <h3 className="text-xl font-semibold mb-3">Return Conditions</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>Product must be returned in original condition</li>
          <li>Return shipping costs are the responsibility of the customer</li>
          <li>Refunds exclude original shipping costs and transaction fees</li>
          <li>Proof of purchase is required</li>
        </ul>

        <h3 className="text-xl font-semibold mb-3">How to Initiate a Return</h3>
        <p className="mb-4">
          To start the return process, please contact our customer service team at{' '}
          <a href="mailto:support@theschumann.com" className="text-purple-600 hover:text-purple-800">
            support@theschumann.com
          </a>
        </p>

        <h3 className="text-xl font-semibold mb-3">Refund Process</h3>
        <p className="mb-4">
          Once we receive and inspect the returned item, we will process your refund within 5-7 business days.
          The refund will be automatically applied to your original method of payment.
        </p>

        <div className="bg-purple-50 p-4 rounded-lg mt-6">
          <h3 className="text-xl font-semibold mb-3">Our Commitment</h3>
          <p className="mb-0">
            We're committed to ensuring your satisfaction with our products. If you have any questions about our return policy or need assistance, our customer service team is available 24/7 to help.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ReturnPolicy; 