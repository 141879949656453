import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDownIcon, PhotoIcon, DocumentTextIcon, XMarkIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import SEO from './SEO';

export default function Frequencies() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [viewMode, setViewMode] = useState('gallery');
  const [selectedCategory, setSelectedCategory] = useState("Brain Wave Entrainment");
  const [loadedCategories, setLoadedCategories] = useState(["Brain Wave Entrainment"]);
  const [isWaveInfoExpanded, setIsWaveInfoExpanded] = useState(false);

  const handleImageClick = (image, category, index) => {
    setSelectedImage(image);
    setCurrentCategory(category);
    setCurrentIndex(index);
  };

  const handleClose = () => {
    setSelectedImage(null);
    setCurrentCategory(null);
    setCurrentIndex(0);
  };

  const handlePrevImage = () => {
    if (!currentCategory) return;
    const categoryImages = frequencies
      .find(cat => cat.category === currentCategory)
      ?.items.filter(item => item.image) || [];
    const newIndex = (currentIndex - 1 + categoryImages.length) % categoryImages.length;
    setCurrentIndex(newIndex);
    setSelectedImage(categoryImages[newIndex].image);
  };

  const handleNextImage = () => {
    if (!currentCategory) return;
    const categoryImages = frequencies
      .find(cat => cat.category === currentCategory)
      ?.items.filter(item => item.image) || [];
    const newIndex = (currentIndex + 1) % categoryImages.length;
    setCurrentIndex(newIndex);
    setSelectedImage(categoryImages[newIndex].image);
  };

  // Image Modal Component
  const ImageModal = () => (
    <AnimatePresence>
      {selectedImage && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black/90"
          onClick={handleClose}
        >
          <div className="relative w-full h-full flex items-center justify-center"
               onClick={e => e.stopPropagation()}>
            {/* Close button */}
            <button
              onClick={handleClose}
              className="absolute top-4 right-4 text-white hover:text-gray-300 z-50"
            >
              <XMarkIcon className="h-8 w-8" />
            </button>

            {/* Navigation buttons */}
            <button
              onClick={handlePrevImage}
              className="absolute left-4 text-white hover:text-gray-300 z-50"
            >
              <ChevronLeftIcon className="h-8 w-8" />
            </button>
            <button
              onClick={handleNextImage}
              className="absolute right-4 text-white hover:text-gray-300 z-50"
            >
              <ChevronRightIcon className="h-8 w-8" />
            </button>

            {/* Image */}
            <motion.img
              src={selectedImage}
              alt="Zoomed frequency"
              className="max-h-[90vh] max-w-[90vw] object-contain"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  const frequencies = [
    {
      category: "Brain Wave Entrainment",
      items: [
        {
          title: "High Beta Waves",
          frequency: "20-35 Hz",
          description: "Intense Focus & Energy",
          benefits: [
            "• Maximum concentration",
            "• High-level processing",
            "• Complex problem solving",
            "• Peak mental performance"
          ],
          waveSpeed: "6s",
          image: "/freq/brainwave/6.webp"
        },
        {
          title: "Delta Waves",
          frequency: "0.5-4 Hz",
          description: "Deep Sleep & Healing",
          benefits: [
            "• Promotes deep restorative sleep",
            "• Accelerates physical healing",
            "• Enhances immune system",
            "• Access unconscious mind"
          ],
          waveSpeed: "20s",
          image: "/freq/brainwave/1.webp"
        },
        {
          title: "Theta Waves",
          frequency: "4-8 Hz",
          description: "Meditation & Creativity",
          benefits: [
            "• Deep meditation states",
            "• Enhanced creativity",
            "• Spiritual connection",
            "• Vivid visualization"
          ],
          waveSpeed: "15s",
          image: "/freq/brainwave/2.webp"
        },
        {
          title: "Alpha Waves",
          frequency: "8-12 Hz",
          description: "Relaxed Focus",
          benefits: [
            "• Calm alertness",
            "• Stress reduction",
            "• Improved learning",
            "• Mental clarity"
          ],
          waveSpeed: "12s",
          image: "/freq/brainwave/3.webp"
        },
        {
          title: "Beta Waves",
          frequency: "12-30 Hz",
          description: "Active Mind",
          benefits: [
            "• Focused attention",
            "• Problem solving",
            "• Active thinking",
            "• Peak performance"
          ],
          waveSpeed: "8s",
          image: "/freq/brainwave/4.webp"
        },
        {
          title: "Gamma Waves",
          frequency: "30-100 Hz",
          description: "Peak Performance",
          benefits: [
            "• Enhanced perception",
            "• Peak mental clarity",
            "• Advanced cognition",
            "• Heightened awareness"
          ],
          waveSpeed: "5s",
          image: "/freq/brainwave/5.webp"
        },
        {
          title: "Hyper Gamma Waves",
          frequency: "100+ Hz",
          description: "Transcendental States",
          benefits: [
            "• Mystical experiences",
            "• Higher states of consciousness",
            "• Extraordinary awareness",
            "• Spiritual insights"
          ],
          waveSpeed: "4s",
          image: "/freq/brainwave/7.webp"
        }
      ]
    },
    {
      category: "Sacred Solfeggio Frequencies",
      items: [
        {
          title: "The Liberator",
          frequency: "396 Hz",
          description: "Liberation from Fear",
          benefits: [
            "• Releases fear patterns",
            "• Dissolves guilt",
            "• Removes blockages",
            "• Enhances security",
            "• Grounds strength"
          ],
          color: "from-red-500 to-red-600",
          waveSpeed: "9s",
          image: "/freq/solfeggio/1.webp"
        },
        {
          title: "The Connector",
          frequency: "639 Hz",
          description: "Connection & Relationships",
          benefits: [
            "• Harmonizes relationships",
            "• Balances emotions",
            "• Deepens connections",
            "• Heals heart chakra",
            "• Promotes unity"
          ],
          color: "from-pink-500 to-pink-600",
          waveSpeed: "8s",
          image: "/freq/solfeggio/2.webp"
        },
        {
          title: "The Awakener",
          frequency: "852 Hz",
          description: "Spiritual Awakening",
          benefits: [
            "• Enhances intuition",
            "• Expands awareness",
            "• Clears mental blocks",
            "• Deepens meditation",
            "• Activates inspiration"
          ],
          color: "from-purple-500 to-blue-500",
          waveSpeed: "7s",
          image: "/freq/solfeggio/3.webp"
        },
        {
          title: "The Healer",
          frequency: "528 Hz",
          description: "Miracle & DNA Repair",
          benefits: [
            "• Repairs DNA",
            "• Activates healing",
            "• Boosts vitality",
            "• Increases energy",
            "• Restores harmony"
          ],
          color: "from-green-500 to-emerald-500",
          waveSpeed: "6s",
          image: "/freq/solfeggio/4.webp"
        },
        {
          title: "The Grounder",
          frequency: "432 Hz",
          description: "Earth's Natural Tune",
          benefits: [
            "• Natural alignment",
            "• Deep grounding",
            "• Enhanced sleep",
            "• Stress reduction",
            "• Physical harmony"
          ],
          color: "from-emerald-500 to-green-600",
          waveSpeed: "10s",
          image: "/freq/solfeggio/5.webp"
        },
        {
          title: "The Transformer",
          frequency: "741 Hz",
          description: "Expression & Transformation",
          benefits: [
            "• Awakens expression",
            "• Cleanses toxins",
            "• Solves problems",
            "• Transforms reality",
            "• Expands consciousness"
          ],
          color: "from-blue-500 to-indigo-600",
          waveSpeed: "7.4s",
          image: "/freq/solfeggio/6.webp"
        }
      ]
    },
    {
      category: "Planetary Frequencies",
      items: [
        {
          title: "Sun",
          frequency: "126.22 Hz",
          description: "The Centre of Our Solar System",
          benefits: [
            "• Source of life and vitality",
            "• Enhances creativity and expression",
            "• Promotes personal power",
            "• Aligns with tone B in music"
          ],
          color: "from-yellow-500 to-orange-500",
          waveSpeed: "12.6s",
          image: "/freq/planetary/1.webp"
        },
        {
          title: "Neptune",
          frequency: "211.44 Hz",
          description: "Mystical & Spiritual Connection",
          benefits: [
            "• Enhanced spiritual awareness",
            "• Deep intuitive insights",
            "• Dream state activation",
            "• Higher consciousness access"
          ],
          color: "from-blue-400 to-indigo-600",
          waveSpeed: "21.1s",
          image: "/freq/planetary/2.webp"
        },
        {
          title: "Mercury",
          frequency: "141.27 Hz",
          description: "Communication and Expression",
          benefits: [
            "• Enhances communication clarity",
            "• Boosts intellectual understanding",
            "• Improves expression abilities",
            "• Aligns with tone C# in music"
          ],
          color: "from-gray-400 to-blue-400",
          waveSpeed: "14.1s",
          image: "/freq/planetary/3.webp"
        },
        {
          title: "Earth",
          frequency: "126.22 Hz",
          description: "Grounding & Stability",
          benefits: [
            "• Deep earth connection",
            "• Physical stability",
            "• Natural alignment",
            "• Grounding energy"
          ],
          color: "from-emerald-500 to-emerald-600",
          waveSpeed: "15s",
          image: "/freq/planetary/4.webp"
        },
        {
          title: "Uranus",
          frequency: "207.36 Hz",
          description: "Innovation and Change",
          benefits: [
            "• Stimulates innovation",
            "• Promotes forward-thinking",
            "• Facilitates positive change"
          ],
          color: "from-cyan-400 to-blue-500",
          waveSpeed: "20.7s",
          image: "/freq/planetary/5.webp"
        },
        {
          title: "Moon",
          frequency: "210.42 Hz",
          description: "Intuition & Cycles",
          benefits: [
            "• Enhanced intuition",
            "• Emotional balance",
            "• Natural rhythms",
            "• Feminine energy"
          ],
          color: "from-slate-300 to-slate-400",
          waveSpeed: "14s",
          image: "/freq/planetary/6.webp"
        },
        {
          title: "Venus",
          frequency: "221.23 Hz",
          description: "Love & Harmony",
          benefits: [
            "• Heart resonance",
            "• Relationship harmony",
            "• Creative expression",
            "• Beauty frequency"
          ],
          color: "from-pink-500 to-pink-600",
          waveSpeed: "13s",
          image: "/freq/planetary/7.webp"
        },
        {
          title: "Mars",
          frequency: "144.72 Hz",
          description: "Energy & Vitality",
          benefits: [
            "• Physical energy",
            "• Motivation & drive",
            "• Active principles",
            "• Strength & courage"
          ],
          color: "from-red-600 to-red-700",
          waveSpeed: "11s",
          image: "/freq/planetary/8.webp"
        },
        {
          title: "Jupiter",
          frequency: "183.58 Hz",
          description: "Growth & Expansion",
          benefits: [
            "• Abundance energy",
            "• Spiritual growth",
            "• Wisdom activation",
            "• Positive expansion"
          ],
          color: "from-purple-500 to-purple-600",
          waveSpeed: "10s",
          image: "/freq/planetary/9.webp"
        },
        {
          title: "Saturn",
          frequency: "147.85 Hz",
          description: "Structure & Time",
          benefits: [
            "• Discipline frequency",
            "• Time management",
            "• Structure & order",
            "• Karmic balance"
          ],
          color: "from-gray-600 to-gray-700",
          waveSpeed: "16s"
        }
      ]
    },
    {
      category: "Higher Angelic Frequencies",
      items: [
        {
          title: "Divine Change",
          frequency: "555 Hz",
          description: "Transformation & Guidance",
          benefits: [
            "• Facilitates positive change",
            "• Divine guidance activation",
            "• Supports life transitions",
            "• Enhanced adaptability"
          ],
          color: "from-sky-400 to-blue-500",
          waveSpeed: "5.55s",
          image: "/freq/angelic/Black Bold Minimal Work With Us Carousel Instagram Post (Instagram Post)(2)/1.webp"
        },
        {
          title: "Balance & Harmony",
          frequency: "666 Hz",
          description: "Integration & Transcendence",
          benefits: [
            "• Perfect balance alignment",
            "• Spiritual integration",
            "• Transcends duality",
            "• Higher energy connection"
          ],
          color: "from-amber-400 to-orange-500",
          waveSpeed: "6.66s",
          image: "/freq/angelic/Black Bold Minimal Work With Us Carousel Instagram Post (Instagram Post)(2)/2.webp"
        },
        {
          title: "Divine Wisdom",
          frequency: "777 Hz",
          description: "Spiritual Awakening",
          benefits: [
            "• Deep spiritual awareness",
            "• Enhanced intuition",
            "• Divine connection",
            "• Higher truth alignment"
          ],
          color: "from-violet-400 to-purple-500",
          waveSpeed: "7.77s",
          image: "/freq/angelic/Black Bold Minimal Work With Us Carousel Instagram Post (Instagram Post)(2)/3.webp"
        },
        {
          title: "Infinite Abundance",
          frequency: "888 Hz",
          description: "Prosperity & Fulfillment",
          benefits: [
            "• Material abundance",
            "• Spiritual prosperity",
            "• Divine manifestation",
            "• Infinite potential"
          ],
          color: "from-yellow-400 to-amber-500",
          waveSpeed: "8.88s",
          image: "/freq/angelic/Black Bold Minimal Work With Us Carousel Instagram Post (Instagram Post)(2)/4.webp"
        },
        {
          title: "Universal Completion",
          frequency: "999 Hz",
          description: "Enlightenment & Ascension",
          benefits: [
            "• Spiritual completion",
            "• Universal consciousness",
            "• Higher dimensional access",
            "• Soul journey advancement"
          ],
          color: "from-white to-purple-300",
          waveSpeed: "9.99s",
          image: "/freq/angelic/Black Bold Minimal Work With Us Carousel Instagram Post (Instagram Post)(2)/5.webp"
        },
        {
          title: "Angelic Harmony",
          frequency: "1111 Hz",
          description: "Divine Connection & Unity",
          benefits: [
            "• Angelic resonance",
            "• Higher dimensional access",
            "• Unity consciousness",
            "• Divine protection"
          ],
          color: "from-white to-gold-300",
          waveSpeed: "11.11s",
          image: "/freq/angelic/Black Bold Minimal Work With Us Carousel Instagram Post (Instagram Post)(2)/6.webp"
        }
      ]
    },
    {
      category: "Tesla Frequencies",
      items: [
        {
          title: "Divine Trinity",
          frequency: "333 Hz",
          description: "Balance & Manifestation",
          benefits: [
            "• Perfect balance activation",
            "• Trinity force alignment",
            "• Manifestation amplifier",
            "• Energy field harmonizer"
          ],
          color: "from-blue-400 via-purple-400 to-blue-400",
          waveSpeed: "3.33s",
          image: "/freq/tesla/1.webp"
        },
        {
          title: "Harmonic Balance",
          frequency: "639 Hz",
          description: "Connection & Relationships",
          benefits: [
            "• Heart-mind coherence",
            "• Relationship harmony",
            "• Universal connection",
            "• Energy field balance"
          ],
          color: "from-purple-400 via-pink-400 to-purple-400",
          waveSpeed: "6.39s",
          image: "/freq/tesla/2.webp"
        },
        {
          title: "Universal Key",
          frequency: "999 Hz",
          description: "Completion & Transformation",
          benefits: [
            "• Higher consciousness access",
            "• Energy field completion",
            "• Universal alignment",
            "• Tesla's master frequency"
          ],
          color: "from-gold-400 via-white to-gold-400",
          waveSpeed: "9.99s",
          image: "/freq/tesla/3.webp"
        },
        {
          title: "Tesla's Resonance",
          frequency: "432 Hz",
          description: "Natural Harmony",
          benefits: [
            "• Earth frequency alignment",
            "• Deep cellular resonance",
            "• Energy field optimization",
            "• Natural healing activation"
          ],
          color: "from-emerald-400 via-blue-400 to-emerald-400",
          waveSpeed: "4.32s",
          image: "/freq/tesla/4.webp"
        }
      ]
    }
  ];

  const FrequencyGallery = ({ category, items }) => {
    const [loadedImages, setLoadedImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const isDetailsOnlyCategory = category === "Quantum Healing Frequencies" || category === "Tesla's 3-6-9 Frequencies";
    
    useEffect(() => {
      setIsLoading(true);
      setLoadedImages([]);
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    }, [category]);

    useEffect(() => {
      const observerOptions = {
        root: null,
        rootMargin: '50px',
        threshold: 0.1
      };

      const imageObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const imageIndex = parseInt(entry.target.dataset.index);
            if (!loadedImages.includes(imageIndex)) {
              setLoadedImages(prev => [...prev, imageIndex]);
            }
            imageObserver.unobserve(entry.target);
          }
        });
      }, observerOptions);

      // Get all image placeholders
      const placeholders = document.querySelectorAll('.image-placeholder');
      placeholders.forEach(placeholder => {
        imageObserver.observe(placeholder);
      });

      return () => {
        imageObserver.disconnect();
      };
    }, [category, loadedImages]);

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="mb-16"
      >
        <h2 className="text-2xl font-bold text-center mb-8 bg-gradient-to-r from-purple-400 to-pink-600 bg-clip-text text-transparent">
          {category}
          {isDetailsOnlyCategory && viewMode === 'gallery' && (
            <span className="block text-sm text-gray-400 mt-2">
              Switch to Details View to see these frequencies
            </span>
          )}
        </h2>

        {viewMode === 'gallery' ? (
          !isDetailsOnlyCategory ? (
            /* Image Gallery */
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {items.map((item, index) => (
                item.image && (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="relative group aspect-square cursor-pointer image-placeholder"
                    data-index={index}
                    onClick={() => handleImageClick(item.image, category, index)}
                  >
                    <div className="w-full h-full rounded-lg overflow-hidden">
                      {loadedImages.includes(index) ? (
                        <motion.img
                          src={item.image}
                          alt={item.title}
                          className="w-full h-full object-cover"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 0.3 }}
                        />
                      ) : (
                        <div className="w-full h-full bg-purple-900/20 flex items-center justify-center">
                          <div className="w-8 h-8 border-2 border-purple-500 border-t-transparent rounded-full animate-spin"></div>
                        </div>
                      )}
                      <div className="absolute inset-0 bg-gradient-to-t from-black/20 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                    </div>
                  </motion.div>
                )
              ))}
            </div>
          ) : null
        ) : (
          /* Frequency Details */
          <div className="space-y-4">
            {items.map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="relative p-4 rounded-lg bg-purple-900/20 overflow-hidden"
              >
                {/* Animated background */}
                <div className="absolute inset-0 overflow-hidden opacity-10">
                  <motion.div
                    animate={{
                      y: [0, -10, 0],
                    }}
                    transition={{
                      duration: item.waveSpeed || "10s",
                      repeat: Infinity,
                      ease: "easeInOut",
                    }}
                    className={`absolute inset-0 bg-gradient-to-r ${
                      item.color || 'from-purple-500 to-pink-500'
                    }`}
                  />
                </div>

                <div className="relative z-10">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-lg font-semibold text-purple-400">
                      {item.title}
                    </h3>
                    <span className="text-sm font-mono bg-purple-900/40 px-2 py-1 rounded">
                      {item.frequency}
                    </span>
                  </div>
                  <p className="text-gray-300 mb-2">{item.description}</p>
                  <ul className="space-y-1">
                    {item.benefits.map((benefit, idx) => (
                      <li key={idx} className="text-gray-400 text-sm">
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </div>
              </motion.div>
            ))}
          </div>
        )}
      </motion.div>
    );
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    if (!loadedCategories.includes(category)) {
      setLoadedCategories([...loadedCategories, category]);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white pb-20 pt-24 sm:pt-32">
      <SEO 
        title="Frequencies - The Schumann"
        description="Explore our comprehensive frequency guide including Brain Wave Entrainment, Sacred Solfeggio, Planetary, and Higher Angelic frequencies."
      />

      <ImageModal />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h1 
          className="text-3xl sm:text-5xl md:text-7xl font-bold mx-auto max-w-[95%] sm:max-w-none text-center mb-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-purple-200 to-white inline-block">
            Frequency
          </span>
          <br />
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600 inline-block">
            Guide
          </span>
        </motion.h1>
        
        {/* Understanding Wave Types section - Now Collapsible */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-12"
        >
          <button
            onClick={() => setIsWaveInfoExpanded(!isWaveInfoExpanded)}
            className="w-full bg-purple-900/20 rounded-xl p-6 border border-purple-500/20 transition-colors hover:bg-purple-900/30 group"
          >
            <div className="flex items-center justify-between">
              <h2 className="text-2xl font-bold bg-gradient-to-r from-purple-400 to-pink-600 bg-clip-text text-transparent">
                Understanding Wave Types
              </h2>
              <ChevronDownIcon 
                className={`h-6 w-6 text-purple-400 transition-transform duration-300 ${
                  isWaveInfoExpanded ? 'rotate-180' : ''
                }`}
              />
            </div>
          </button>

          <AnimatePresence>
            {isWaveInfoExpanded && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="overflow-hidden"
              >
                <div className="bg-purple-900/20 rounded-b-xl p-6 border-x border-b border-purple-500/20">
                  <div className="grid md:grid-cols-2 gap-8">
                    {/* Sine Wave */}
                    <div className="relative overflow-hidden rounded-lg bg-black/30 p-6 border border-purple-500/20 h-[200px]">
                      <div className="relative z-10">
                        <h3 className="text-xl font-semibold text-purple-400 mb-3">Sine Waves</h3>
                        <p className="text-gray-300 text-sm">Smooth, natural waves that mimic Earth's frequencies. Perfect for meditation, sleep, and subtle energy work.</p>
                      </div>
                      <div className="absolute inset-0 opacity-30">
                        <svg className="w-full h-full" viewBox="0 0 1000 200" preserveAspectRatio="none">
                          <motion.path
                            d="M0 100 Q 250 25, 500 100 T 1000 100"
                            stroke="url(#sineGradient)"
                            strokeWidth="2"
                            fill="none"
                            initial={{ pathLength: 0 }}
                            animate={{ 
                              pathLength: [0, 1],
                              pathOffset: [0, 1]
                            }}
                            transition={{
                              duration: 3,
                              ease: "linear",
                              repeat: Infinity
                            }}
                          />
                          <defs>
                            <linearGradient id="sineGradient" x1="0" y1="0" x2="100%" y2="0">
                              <stop offset="0%" stopColor="rgb(168, 85, 247)" />
                              <stop offset="100%" stopColor="rgb(236, 72, 153)" />
                            </linearGradient>
                          </defs>
                        </svg>
                        <svg className="w-full h-full absolute top-0 left-0" viewBox="0 0 1000 200" preserveAspectRatio="none">
                          <motion.path
                            d="M0 100 Q 250 175, 500 100 T 1000 100"
                            stroke="url(#sineGradient2)"
                            strokeWidth="2"
                            fill="none"
                            initial={{ pathLength: 0 }}
                            animate={{ 
                              pathLength: [0, 1],
                              pathOffset: [0, 1]
                            }}
                            transition={{
                              duration: 3,
                              ease: "linear",
                              delay: 1.5,
                              repeat: Infinity
                            }}
                          />
                          <defs>
                            <linearGradient id="sineGradient2" x1="0" y1="0" x2="100%" y2="0">
                              <stop offset="0%" stopColor="rgb(236, 72, 153)" />
                              <stop offset="100%" stopColor="rgb(168, 85, 247)" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                    </div>

                    {/* Square Wave */}
                    <div className="relative overflow-hidden rounded-lg bg-black/30 p-6 border border-purple-500/20 h-[200px]">
                      <div className="relative z-10">
                        <h3 className="text-xl font-semibold text-purple-400 mb-3">Square Waves</h3>
                        <p className="text-gray-300 text-sm">More intense and direct. Ideal for focus, energy boosting, and deep transformation work.</p>
                      </div>
                      <div className="absolute inset-0 opacity-30">
                        <svg className="w-full h-full" viewBox="0 0 1000 200" preserveAspectRatio="none">
                          <motion.path
                            d="M0 100 L 0 50 L 250 50 L 250 150 L 500 150 L 500 50 L 750 50 L 750 150 L 1000 150"
                            stroke="url(#squareGradient)"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="square"
                            initial={{ pathLength: 0 }}
                            animate={{ 
                              pathLength: [0, 1],
                              pathOffset: [0, 1]
                            }}
                            transition={{
                              duration: 3,
                              ease: "linear",
                              repeat: Infinity
                            }}
                          />
                          <defs>
                            <linearGradient id="squareGradient" x1="0" y1="0" x2="100%" y2="0">
                              <stop offset="0%" stopColor="rgb(236, 72, 153)" />
                              <stop offset="100%" stopColor="rgb(168, 85, 247)" />
                            </linearGradient>
                            </defs>
                        </svg>
                        <svg className="w-full h-full absolute top-0 left-0" viewBox="0 0 1000 200" preserveAspectRatio="none">
                          <motion.path
                            d="M0 150 L 0 100 L 250 100 L 250 50 L 500 50 L 500 150 L 750 150 L 750 100 L 1000 100"
                            stroke="url(#squareGradient2)"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="square"
                            initial={{ pathLength: 0 }}
                            animate={{ 
                              pathLength: [0, 1],
                              pathOffset: [0, 1]
                            }}
                            transition={{
                              duration: 3,
                              ease: "linear",
                              delay: 1.5,
                              repeat: Infinity
                            }}
                          />
                          <defs>
                            <linearGradient id="squareGradient2" x1="0" y1="0" x2="100%" y2="0">
                              <stop offset="0%" stopColor="rgb(168, 85, 247)" />
                              <stop offset="100%" stopColor="rgb(236, 72, 153)" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>

                  {/* Finding Your Frequency */}
                  <div className="mt-6 bg-black/30 p-6 rounded-lg border border-purple-500/20">
                    <div className="relative">
                      <h3 className="text-lg font-semibold text-purple-400 mb-4">Finding Your Frequency</h3>
                      <p className="text-gray-300 text-sm mb-4">
                        Everyone responds differently to frequencies. What works perfectly for one person might feel too intense or too subtle for another.
                      </p>
                      <p className="text-gray-300 text-sm">We encourage you to experiment with different:</p>
                      <ul className="mt-2 space-y-1 text-sm">
                        <li className="flex items-center text-purple-300">
                          <span className="mr-2">•</span>
                          Wave types (sine vs square)
                        </li>
                        <li className="flex items-center text-purple-300">
                          <span className="mr-2">•</span>
                          Frequency ranges
                        </li>
                        <li className="flex items-center text-purple-300">
                          <span className="mr-2">•</span>
                          Duration of exposure
                        </li>
                        <li className="flex items-center text-purple-300">
                          <span className="mr-2">•</span>
                          Times of day
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* What You Might Feel section */}
                  <div className="mt-6 bg-black/30 p-4 rounded-lg border border-purple-500/20">
                    <h3 className="text-lg font-semibold text-purple-400 mb-4">🌟 What You Might Feel</h3>
                    <div className="grid md:grid-cols-2 gap-6">
                      <div>
                        <h4 className="text-sm font-medium text-purple-300 mb-2">First Few Days</h4>
                        <ul className="text-gray-300 text-sm space-y-1">
                          <li>• Better sleep & relaxation</li>
                          <li>• Clearer mind & focus</li>
                          <li>• Subtle tingling sensations</li>
                          <li>• Increased dream recall</li>
                        </ul>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-purple-300 mb-2">After 1-2 Weeks</h4>
                        <ul className="text-gray-300 text-sm space-y-1">
                          <li>• Enhanced energy & mood</li>
                          <li>• Natural attunement</li>
                          <li>• Heightened intuition</li>
                          <li>• Better emotional balance</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
        
        <div className="text-center max-w-3xl mx-auto mb-12 space-y-4">
          <p className="text-lg text-gray-300">
            Explore the complete range of frequencies available in the Schumann Pro V2 device.
            Each frequency is carefully selected to provide specific benefits and healing properties.
          </p>
          <p className="text-sm text-purple-400/80">
            From brain waves to sacred geometries, discover the perfect frequency for your needs.
          </p>
        </div>

        {/* View Mode Selection */}
        <div className="flex justify-center gap-4 mb-8">
          <motion.button
            onClick={() => setViewMode('gallery')}
            className={`flex items-center gap-2 px-6 py-3 rounded-lg transition-all ${
              viewMode === 'gallery'
                ? 'bg-purple-600 text-white'
                : 'bg-purple-900/30 text-gray-300 hover:bg-purple-900/40'
            }`}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <PhotoIcon className="h-5 w-5" />
            Gallery View
          </motion.button>
          <motion.button
            onClick={() => setViewMode('details')}
            className={`flex items-center gap-2 px-6 py-3 rounded-lg transition-all ${
              viewMode === 'details'
                ? 'bg-purple-600 text-white'
                : 'bg-purple-900/30 text-gray-300 hover:bg-purple-900/40'
            }`}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <DocumentTextIcon className="h-5 w-5" />
            Details View
          </motion.button>
        </div>

        {/* Category Selection */}
        <div className="mb-12 flex flex-wrap gap-4 justify-center">
          {frequencies.map((category, idx) => (
            <button
              key={idx}
              onClick={() => handleCategoryClick(category.category)}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                selectedCategory === category.category
                  ? 'bg-purple-600 text-white'
                  : 'bg-purple-900/20 text-purple-300 hover:bg-purple-900/40'
              }`}
            >
              {category.category}
            </button>
          ))}
        </div>

        {/* Display selected category */}
        <AnimatePresence mode="wait">
          {loadedCategories.includes(selectedCategory) && (
            <FrequencyGallery
              key={selectedCategory}
              {...frequencies.find(cat => cat.category === selectedCategory)}
            />
          )}
        </AnimatePresence>

        {/* User Guide Button */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-24">
          <div className="bg-gradient-to-r from-purple-900/30 to-pink-900/30 p-6 rounded-xl border border-purple-500/20">
            <h3 className="text-xl font-bold text-purple-400 mb-4 text-center">
              Want to Learn More?
            </h3>
            <p className="text-gray-300 mb-6 text-center">
              Check out our comprehensive user guide for detailed instructions and tips
            </p>
            <div className="flex justify-center">
              <a
                href="/user-guide"
                className="inline-flex items-center px-8 py-3 rounded-lg bg-black/30 text-white font-medium transition-all duration-300 transform hover:scale-105 border border-purple-500/20 hover:bg-black/50"
              >
                <span className="mr-2">View Full User Guide</span>
                <ChevronRightIcon className="h-5 w-5 text-purple-400" />
              </a>
            </div>
          </div>
        </div>

        {/* Sticky Button */}
        <div className="fixed bottom-0 left-0 right-0 bg-gradient-to-r from-black to-purple-900/90 backdrop-blur-md p-4 border-t border-purple-500/20 z-50">
          <div className="max-w-7xl mx-auto flex justify-between items-center gap-1 sm:gap-4">
            <div className="text-sm sm:text-lg font-bold text-white whitespace-nowrap">
              Transform Your Well-being Today
            </div>
            <Link
              to="/product#products"
              className="bg-white font-semibold rounded-lg 
                hover:bg-gray-100 transition-all duration-300 transform hover:scale-105
                border border-gray-200 px-4 sm:px-6 py-2 sm:py-3 flex items-center gap-0.5 sm:gap-2 whitespace-nowrap"
            >
              <span className="bg-gradient-to-r from-[#FFD700] to-[#B8860B] bg-clip-text text-transparent text-sm sm:text-base">
                Shop Now
              </span>
              <ArrowRightIcon className="h-4 w-4 sm:h-5 sm:w-5 text-[#B8860B]" />
            </Link>
          </div>
        </div>

        {/* Image Modal */}
        <AnimatePresence>
          {selectedImage && (
            <ImageModal />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
} 